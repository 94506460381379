import { Keyboard } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import popularNovelStyles from "./popular-novels.module.css";
// @ts-expect-error - Import swiper css
import "swiper/css";
// @ts-expect-error - Import swiper css
import "swiper/css/keyboard";

import { useQuery } from "@tanstack/react-query";
import { ListResponse, Novel } from "../../../api";
import { useResponsive } from "../../../common/utils/useResponsive";
import { useRef, useState } from "react";
import type { Swiper as SwiperType } from "swiper/types";
import medal1 from "../../../assets/images/home/emojione_1st-place-medal.png";
import medal2 from "../../../assets/images/home/emojione_2nd-place-medal.png";
import medal3 from "../../../assets/images/home/emojione_3rd-place-medal.png";
import { BiStar } from "react-icons/bi";
import formatNumber from "../../../common/utils/format_number";

const medals = [medal1, medal2, medal3];

export default function TopRanked() {
  const { data: { results: novels } = {} } = useQuery<ListResponse<Novel>>({
    queryKey: ["/novels/", "?listing=popular"],
  });
  const count = useResponsive({
    "0": 3,
    "640": 3,
    "768": 3,
    "1024": 3,
    "1280": 3,
    "1536": 3,
  });
  const spaceBetween = useResponsive({
    "0": 12,
    "640": 24,
    "768": 24,
    "1024": 24,
    "1280": 24,
    "1536": 36,
  });

  const ref = useRef<SwiperType>(null);
  const [active, setActive] = useState(1);

  return (
    <div className="w-md flex-grow max-w-full ">
      <h2 className="mb-4 sm:mb-8 max-md:text-center">Top Ranked</h2>
      <div className="rounded-4xl sm:px-8 sm:py-4 sm:mb-24 sm:bg-[#fff6ef] w-full">
        <Swiper
          key={count}
          modules={[Keyboard]}
          keyboard
          spaceBetween={spaceBetween}
          initialSlide={active}
          enabled={count < 3}
          centeredSlides
          centeredSlidesBounds={count === 3}
          speed={300}
          slidesPerView={count}
          onSlideChange={(swiper) => setActive([1, 0, 2][swiper.activeIndex])}
          onSwiper={(swiper) => (ref.current = swiper)}
        >
          {[1, 0, 2].map((e, i) => {
            return (
              <SwiperSlide
                key={e}
                className={
                  "py-4 md:py-8 " +
                  (count > 1 ? popularNovelStyles.zoomOnActive : "")
                }
                onClick={() => {
                  ref.current?.slideTo(i);
                }}
              >
                <div className={popularNovelStyles.zoom}>
                  <img
                    className="w-full novel-cover "
                    src={novels?.[e].cover_image}
                  />
                  <img
                    className="w-8 sm:w-12 block mx-auto -mt-0.5"
                    src={medals[e]}
                  />
                </div>
                <div className="max-sm:hidden">
                  <div className=" my-4 mt-8" />
                  <h3 className="text-center text-sm">{novels?.[e].title}</h3>
                  <div className="flex gap-x-1 mt-1 text-smaller">
                    <div className="basis-0 flex-grow">
                      <span className="text-primary-accent capitalize">
                        {novels?.[e].genres[0]}
                      </span>
                    </div>
                    <div className="text-gray-600">
                      <BiStar className="inline-block align-middle mb-1" />
                      {novels?.[e].num_stars}
                    </div>
                    <div className="text-gray-600">
                      <BiStar className="inline-block align-middle mb-1" />
                      {formatNumber(novels?.[e].num_views || 0)}
                    </div>
                  </div>
                  <div className="mb-2 min-h-4 max-w-17/20 whitespace-pre-wrap">
                    {novels?.[e].author.name?.padEnd(20, " ")}&nbsp;
                  </div>
                  <p className="text-center line-clamp-10 text-sm font-light">
                    {novels?.[e].description}
                  </p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
