import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { CgFileAdd } from "react-icons/cg";
import { FaRegEdit } from "react-icons/fa";
import { RxPieChart } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import defaultCoverImage from "../../../assets/images/defaultBookCover.png";
import completeImage from "../../../assets/images/rb_2149279470.png";
import "../../../components/modal-children-style/modal-delete.css";
import Modal from "../../../components/modal/Modal";
import Loading from "../../../components/splash/loading/Loading";
import NoResult from "../../../components/splash/no-result/NoResult";
import toastManager from "../../../components/toast/ToasterManager";
import { useDeleteNovel } from "../../../redux/actions/bookActions";
import "./Account.stories.css";

function AccountStories() {
  const navigate = useNavigate();
  const deleteNovel = useDeleteNovel();
  const [deleteId, setDeleteId] = useState(null);
  const { loading, data: { results: myNovels } = {} } = useQuery({
    queryKey: ["novels/mine/"],
  });

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [isOpen, setIsOpen] = useState({
    delete: false,
    complete: false,
  });

  const closeModal = () => {
    setIsOpen({
      delete: false,
      complete: false,
    });
  };

  const handleModalClick = (option) => {
    closeModal();
    if (option === "delete") {
      setIsOpen((prev) => ({ ...prev, delete: true }));
    } else if (option === "complete") {
      setIsOpen((prev) => ({ ...prev, complete: true }));
    } else return;
  };

  const handleDeleteNovel = async () => {
    try {
      setLoadingDelete(true);
      const response = await deleteNovel(deleteId);

      if (response.meta.requestStatus == "fulfilled") {
        console.log("delete novel response", response);
        toastManager.addToast({
          message: "Novel deleted successfully",
          type: "success",
        });
        return;
      }

      toastManager.addToast({
        message: "Failed to delete novel",
        type: "error",
      });
    } catch (error) {
      console.log(error.response?.message || error.message);
      toastManager.addToast({
        message: "Failed to delete novel",
        type: "success",
      });
    } finally {
      setLoadingDelete(false);
      closeModal();
      handleGetNovels();
    }
  };

  return (
    <div>
      <section className="ac__stories__section__one">
        <button onClick={() => navigate("/create-novel")}>
          <CgFileAdd /> Add stories
        </button>
      </section>
      {loading || !myNovels ? (
        <Loading />
      ) : myNovels.length === 0 ? (
        <NoResult
          header="You do not currently have any novel"
          content="Create a novel to get started"
        />
      ) : (
        <>
          <section className="ac__stories__section__two">
            <div className="ac_stories_header  ac__stories__section__two__entry">
              <h1 className="ac__stories__header  ac__stories__section__two__entry__stories">
                Stories
              </h1>
              <h1 className="ac__stories__header  ac__stories__section__two__entry__state">
                State
              </h1>
              <h1 className="ac__stories__header  ac__stories__section__two__entry__chapter">
                Chapter
              </h1>
              <h1 className="ac__stories__header  ac__stories__section__two__entry__word">
                Pages
              </h1>
              <h1 className="ac__stories__header  ac__stories__section__two__entry__collection">
                Collections
              </h1>
              <h1 className="ac__stories__header  ac__stories__section__two__entry__actions">
                Action
              </h1>
            </div>
            {myNovels?.map((novel, i) => {
              return (
                <div className="ac__stories__section__two__entry">
                  <h1
                    onClick={() => navigate(`/book-chapters/${novel.id}`)}
                    className="ac__stories__section__two__entry__stories"
                  >
                    <img
                      className="aspect-3/4 h-24 rounded-lg"
                      src={
                        novel?.cover_image
                          ? novel.cover_image
                          : defaultCoverImage
                      }
                      alt=""
                    />
                    {novel?.title}
                  </h1>
                  <h1
                    onClick={() => navigate(`/book-chapters/${novel.id}`)}
                    className="ac__stories__section__two__entry__state"
                  >{`${novel?.completed ? "Completed" : "Ongoing"}`}</h1>
                  <h1
                    onClick={() => navigate(`/book-chapters/${novel.id}`)}
                    className="ac__stories__section__two__entry__chapter"
                  >
                    {novel.num_chapters}
                  </h1>
                  <h1
                    onClick={() => navigate(`/book-chapters/${novel.id}`)}
                    className="ac__stories__section__two__entry__word"
                  >
                    {novel.num_pages}
                  </h1>
                  <h1
                    onClick={() => navigate(`/book-chapters/${novel.id}`)}
                    className="ac__stories__section__two__entry__collection"
                  >
                    {novel?.num_collection}
                  </h1>
                  <h1 className="ac__stories__section__two__entry__actions">
                    <FaRegEdit
                      className="ac__stories__section__two__entry__actions__edit"
                      onClick={() => navigate(`/book-chapters/${novel.id}`)}
                    />

                    <RxPieChart
                      className="ac__stories__section__two__entry__actions__edit"
                      onClick={() => {
                        handleModalClick("complete");
                      }}
                    />

                    <BiTrash
                      className="ac__stories__section__two__entry__actions__delete"
                      onClick={() => {
                        setDeleteId(novel?.id);
                        handleModalClick("delete");
                      }}
                    />
                  </h1>
                </div>
              );
            })}
          </section>
        </>
      )}

      {/* DELETE MODAL */}
      <Modal isOpen={isOpen.delete} onClose={closeModal}>
        <div className="modal__delete">
          <h1>Are you sure you want to delete?</h1>
          <p>This action is permanent and not reversible</p>
          <button onClick={handleDeleteNovel} disabled={loadingDelete}>
            {loadingDelete ? (
              <ClipLoader color="#669933" size={20} />
            ) : (
              "Delte permanently"
            )}
          </button>
        </div>
      </Modal>

      {/* NOVEL COMPLETION MODAL */}
      <Modal isOpen={isOpen.complete} onClose={closeModal}>
        <div className="modal__delete">
          <img src={completeImage} />
          <h1>Novel completion application</h1>
          <p>
            Specify the reason why you want to end the story. For example, your
            story is logically complete, or you want to start writing the next
            part.
          </p>
          <textarea
            placeholder="Please describe the reason why you want to end the novel."
            type="text"
          />
          <span className="modal__delete__button__wrap">
            <button onClick={closeModal}>Cancel</button>
            <button onClick={handleDeleteNovel} disabled={loadingDelete}>
              {loadingDelete ? (
                <ClipLoader color="#669933" size={20} />
              ) : (
                "Send Request"
              )}
            </button>
          </span>
        </div>
      </Modal>
    </div>
  );
}

export default AccountStories;
