import { BsEye } from "react-icons/bs";
import { Novel } from "../../../api";
import formatNumber from "../../../common/utils/format_number";
import { BiStar } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

export default function TrendingNovelItem({
  novel,
  index,
  placeholder = false,
  className = "flex-grow w-30 mb-8",
}: {
  novel?: Novel;
  index?: number;
  placeholder?: boolean;
  className?: string;
}) {
  return (
    <Link className={"flex w-full " + className} to={"/book/" + novel?.id}>
      <img src={novel?.cover_image} className="w-20 novel-cover" />
      {placeholder ? null : (
        <div className="ml-4 flex-grow">
          <div className="font-bold font-inter text-sm leading-tight mt-1 line-clamp-2 overflow-ellipsis mb-0.5">
            {novel?.title}
          </div>
          <div className="flex-grow" />
          <div className="flex capitalize justify-between text-sm">
            <span className="text-primary-accent">{novel?.genres[0]}</span>
            <span className=" ">
              <BiStar className="inline-block align-middle mb-1" />{" "}
              {novel?.num_stars}
              <BsEye className="inline-block align-middle mb-1 ml-1" />{" "}
              {formatNumber(novel?.num_views || 0)}
            </span>
          </div>
          <span className=" ">{novel?.author?.name}</span>
        </div>
      )}
    </Link>
  );
}
