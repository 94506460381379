import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header2/Header";
import Modal from "../../../components/modal/Modal";
import Loading from "../../../components/splash/loading/Loading";
import TextEditor from "../../../components/ui/text-editor/TextEditor";
import { useQuery } from "@tanstack/react-query";
import { MyNovelChapter, MyNovelChapterInfo } from "../../../api";
import sentenceCase from "../../../common/utils/sentence_case";
import AppButton from "../../../components/AppButton";
import {
  useCreateNovelChapter,
  usePublishing,
  useSaveAsDraft,
} from "./bookChapterActions";
import NovelChapterList from "./NovelChapterList";
import "./book-chapters.css";

function BookChapters() {
  const { id: novelId } = useParams();
  // const chapterState = useRef(ChapterState.unsaved);

  // Some Information About The Chapter Being Editted
  const [chapterBeingEditted, setChapterBeingEditted] =
    useState<MyNovelChapter | null>(null);
  const [title, setTitle] = useState("");
  const [contentWrite, setContentWrite] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [isChapterOpen, setIsChapterOpen] = useState(false);
  // Fetch the current chapter
  const currentChapterQuery = useQuery<MyNovelChapterInfo>({
    queryKey: [
      "novels/mine/",
      novelId,
      "/chapters/",
      chapterBeingEditted?.chapter,
      "/draft/",
    ],
    enabled: !!chapterBeingEditted?.chapter,
  });

  const [resetKey, setResetKey] = useState("");
  useEffect(() => {
    if (currentChapterQuery.data) {
      setTitle(currentChapterQuery.data.title);
      setContentWrite(currentChapterQuery.data.draft);
      setResetKey((resetKey) => resetKey + 1);
    }
  }, [currentChapterQuery.data]);

  const [createChapterLoading, handleCreateNewChapter] = useCreateNovelChapter(
    novelId,
    setChapterBeingEditted
  );
  const [publishLoading, handlePublish] = usePublishing(
    novelId,
    chapterBeingEditted!,
    title,
    contentWrite
  );
  const [saveAsDraftLoading, handleSaveAsDraft] = useSaveAsDraft(
    novelId,
    chapterBeingEditted!,
    title,
    contentWrite,
    setChapterBeingEditted
  );

  // Confirmation Dialogs for publishing and closing chapters
  const [isOpen, setIsOpen] = useState({
    isRequestingPublish: false,
    isRequestingCreateNewChapter: false,
  });
  const closeModal = () => {
    setIsOpen({
      isRequestingPublish: false,
      isRequestingCreateNewChapter: false,
    });
  };

  const requestPublish = () => {
    setIsOpen({ ...isOpen, isRequestingPublish: true });
  };
  const requestCreateNewChapter = () => {
    if (isDirty) {
      setIsOpen({ ...isOpen, isRequestingCreateNewChapter: true });
    } else {
      handleCreateNewChapter();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [chapterBeingEditted]);

  const loading =
    currentChapterQuery.isLoading ||
    createChapterLoading ||
    saveAsDraftLoading ||
    publishLoading;
  return (
    <div className="book__chapters__bg py-4">
      <div className="container book__chapters">
        <Header />
        <section className="book__chapters__section__one pb-4 mb-4 xl:mb-16 pt-4 min-h-screen gap-8">
          <NovelChapterList
            activeChapter={chapterBeingEditted}
            isDirty={isDirty}
            novelId={novelId}
            onEditChapter={(e) => setChapterBeingEditted(e)}
            onCreateNewChapter={() => requestCreateNewChapter()}
          />
          <section className="flex-grow px-8 py-4 rounded-2xl flex flex-col bg-white">
            {loading ? <Loading /> : null}
            {/* TEXT EDITOR FOR NEW CHAPTER */}
            {chapterBeingEditted && isChapterOpen ? (
              <>
                <div className="book__chapters__body__header mb-2">
                  <input
                    type="text"
                    placeholder="Enter chapter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="book__chapters__body__header__chaptertitle"
                    style={{ width: "100%" }}
                  />
                  <h3></h3>
                </div>

                <TextEditor
                  resetKey={resetKey}
                  loadingDraft={currentChapterQuery.isLoading}
                  loadingPublish={publishLoading}
                  setContent={setContentWrite}
                  saveAsDraft={handleSaveAsDraft}
                  requestPublish={requestPublish}
                  // onClose={() => setIsChapterOpen(false)}
                  content={contentWrite}
                />
              </>
            ) : null}

            {chapterBeingEditted && !isChapterOpen ? (
              <div className="">
                {currentChapterQuery.isLoading ? (
                  <Loading />
                ) : (
                  <div className="mt-4">
                    <h1 className="book__chapters__view__title">
                      {`CHAPTER ${parseFloat(chapterBeingEditted.chapter)}: ${
                        chapterBeingEditted.title || "Untitled Chapter"
                      }`}
                    </h1>
                    <span
                      className="bp__section__two__info"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        fontWeight: "600",
                        color: "#777",
                      }}
                    >
                      {/* <p>Word Count: 917</p> */}
                      <p>{`State: ${sentenceCase(
                        chapterBeingEditted.draft_state
                      )}`}</p>
                    </span>
                    <div className="w-full text-center flex items-center justify-center gap-4">
                      <AppButton
                        size="small"
                        className="mx-0 basis-48"
                        onPress={() => setIsChapterOpen((prev) => !prev)}
                        style={{
                          background: "#12121222",
                          color: "#121212",
                        }}
                      >
                        EDIT DRAFT
                      </AppButton>

                      <AppButton
                        size="small"
                        className="mx-0 basis-48"
                        onPress={() => setIsChapterOpen(false)}
                        style={{
                          // background: "#66993322",
                          // color: "#669933",
                          backgroundColor: "#e74c3c22",
                          color: "#e74c3c",
                        }}
                      >
                        CLOSE CHAPTER
                      </AppButton>
                    </div>
                    <p
                      className="text-lg mt-4"
                      dangerouslySetInnerHTML={{
                        __html: currentChapterQuery.data?.draft,
                      }}
                    ></p>
                  </div>
                )}
              </div>
            ) : null}
          </section>
        </section>
      </div>
      <Footer />

      {/* CONFIRM PUBLICATION REQUEST MODAL */}

      <Modal isOpen={isOpen.isRequestingPublish} onClose={closeModal}>
        <div className="admin__modal">
          <h1>Confirm request for publish</h1>
          <p>
            You will not be able to edit your draft afterwards pending approval
          </p>
          <button
            onClick={handlePublish}
            className="btn-size-medium btn-variant-contained app-button mt-4"
          >
            Request Publish
          </button>
        </div>
      </Modal>
      <Modal isOpen={isOpen.isRequestingCreateNewChapter} onClose={closeModal}>
        <div className="admin__modal">
          <h1>Confirm request for publish</h1>
          <p>You have unsaved changes.</p>
          <button
            onClick={handleCreateNewChapter}
            className="btn-size-medium btn-variant-contained app-button mt-4"
          >
            Discard Changes
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default BookChapters;
