import React, { useRef, useState, useEffect, useCallback } from "react";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "./text-editor.css";
import { ClipLoader } from "react-spinners";
import AppButton from "../../AppButton";

const TextEditor = ({
  content,
  setContent,
  loadingDraft,
  loadingPublish,
  saveAsDraft,
  requestPublish,
  resetKey,
  onClose,
}) => {
  /**
   * @type {React.RefObject<ReactQuill>}
   */
  const editorRef = useRef(null);
  const [wordCount, setWordCount] = useState(0);
  const updateWordCount = useCallback((e) => {
    const node = document.createElement("span");
    node.innerHTML = e;
    console.log(node);
    setWordCount(node.textContent.trim().split(/\w+/g).length - 1);
  }, []);
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState("");

  const handleSave = () => {
    saveAsDraft();
    return;
  };

  const exportText = async () => {
    if (editorRef.current) {
      try {
        await requestPublish();
      } catch (error) {
        console.error("Error exporting text:", error);
      }
    }
  };

  const handlePreview = () => {
    if (editorRef.current) {
      setPreviewContent(content);
      setShowPreview(true);
    }
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  return (
    <div className="w-full flex-grow flex flex-col">
      <div className="flex-grow pb-12 lg:h-[50vh] h-[80vh] overflow-hidden flex flex-column">
        <ReactQuill
          key={resetKey}
          ref={editorRef}
          theme="snow"
          onChange={(e) => {
            setContent(e);
            updateWordCount(e);
          }}
          defaultValue={content}
          style={{ height: "100%", flex: 1 }}
        />
      </div>

      <div className="w-full flex items-center gap-x-4 flex-wrap">
        <button
          className="max-sm:flex-grow max-sm:basis-32 save-button"
          onClick={handleSave}
        >
          {loadingDraft ? <ClipLoader color="#669933" size={20} /> : "Save"}
        </button>
        <button
          className="max-sm:flex-grow max-sm:basis-32 preview-button"
          onClick={handlePreview}
        >
          Preview
        </button>

        <div className="word-count flex-grow text-center pt-2 max-sm:order-2 max-sm:w-full">
          Word Count: {wordCount}
        </div>
        <button
          className="max-sm:flex-grow max-sm:basis-32 export-button"
          onClick={exportText}
        >
          {loadingPublish ? (
            <ClipLoader color="#fff" size={20} />
          ) : (
            "Request publish"
          )}
        </button>
        {onClose ? (
          <AppButton
            onPress={onClose}
            size="medium"
            variant="contained"
            className="max-sm:flex-grow max-sm:basis-32 capitalize font-brandon mx-0 h-11 mt-2"
          >
            Close
          </AppButton>
        ) : null}
      </div>

      {showPreview && (
        <div className="preview-modal">
          <div className="preview-content">
            <div className="modal__content__span">
              <button className="close-button" onClick={closePreview}>
                Close
              </button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: previewContent }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
