import { NovelTag, TagCategory } from "../../../api";

export default function parseTagsIntoCategories(
  categories: TagCategory[] | undefined,
  tags: NovelTag[] | undefined
) {
  if (!tags || !categories) return [];

  // Step 1: Group by category
  const groupedByCategory = tags.reduce((acc, item) => {
    console.log({ item });
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {} as Record<string, NovelTag[]>);

  console.log({ groupedByCategory });

  // Step 2: Map to desired structure
  const transformedData = Object.keys(groupedByCategory).map((categoryId) => ({
    title: categories.find((e) => e.id == categoryId)?.name ?? "Uncategorized", // Customize title as needed
    chips: groupedByCategory[categoryId].map((item) => ({
      id: item.id,
      label: item.name,
    })),
  }));

  return transformedData;
}
