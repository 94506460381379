import { Keyboard } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import popularNovelStyles from "./popular-novels.module.css";
// @ts-expect-error - Import swiper css
import "swiper/css";
// @ts-expect-error - Import swiper css
import "swiper/css/keyboard";

import { useQuery } from "@tanstack/react-query";
import { ListResponse, Novel } from "../../../api";
import { useResponsive } from "../../../common/utils/useResponsive";
import { useRef, useState } from "react";
import type { Swiper as SwiperType } from "swiper/types";
import TrendingNovelItem from "./TrendingNovelItem";
import Loading from "../../../components/splash/loading/Loading";

export default function Trending() {
  const { data: { results: novels } = {} } = useQuery<ListResponse<Novel>>({
    queryKey: ["/novels/", "?listing=trending"],
  });
  const count = useResponsive({
    "0": 3,
    "640": 3,
    "768": 3,
    "1024": 4,
    "1280": 4,
    "1536": 4,
  });

  return (
    <div className="w-sm flex-grow max-w-full mb-8">
      <h2 className="mb-4 sm:mb-8 max-md:text-center">Trending Novels</h2>
      <div className="rounded-2xl px-2 sm:px-6 space-y-4 py-4 lg:py-6 w-full bg-[#f7ffef]">
        {novels?.length ? (
          novels
            .slice(0, count)
            .map((e) => (
              <TrendingNovelItem className="flex-grow " novel={e} key={e.id} />
            ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
