import { useEffect, useRef } from "react";
import { FormInputProps, useField } from "./AppForm";
import "./form-components.css";
export default function FormSelect({
  name,
  options,
  label,
  required,
  placeholder,
}: FormInputProps & {
  options: (string | { value: string; label: string })[];
  label: string;
}) {
  const { value, update, error } = useField<string>(name);
  const ref = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (error) {
      ref.current?.setCustomValidity(error);
    } else {
      ref.current?.setCustomValidity("");
    }
  }, [error]);
  return (
    <span className="form__input__block">
      <label htmlFor={name}>{label}</label>
      <select
        required={required}
        name={name}
        ref={ref}
        id={name}
        value={value ?? ""}
        onChange={(e) => update(e.target.value)}
        className="modal__apply__input"
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option
            key={typeof option === "string" ? option : option.value}
            value={typeof option === "string" ? option : option.value}
          >
            {typeof option === "string" ? option : option.label}
          </option>
        ))}
      </select>
      {error && <span className="text-error text-sm">{error}</span>}
    </span>
  );
}
