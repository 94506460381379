import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header2/Header";
import baa from "../../../assets/images/partner.jpg";
import "./beAnAuthor.css";
import { FaPencilAlt } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import { useCallback, useState } from "react";
import { ClipLoader } from "react-spinners";
import toastManager from "../../../components/toast/ToasterManager";
import { useSelector } from "react-redux";
import { useLogin, useSignUp } from "../../../redux/actions/authActions";
import "../../../components/modal-children-style/modal-login.css";
import "../../../components/modal-children-style/modal-signup.css";
import "../../../components/modal-children-style/modal-plan.css";
import "../../../components/modal-children-style/modal-confirmation.css";
import logo from "../../../assets/icons/logo.png";
import google__icon from "../../../assets/icons/google.png";
import facebook__icon from "../../../assets/icons/facebook.png";
import { useNavigate } from "react-router-dom";
import PickTagsModal from "../../main/create-book/createNovelModal";

function BeAnAuthor() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [request_id, setRequest_id] = useState("");
  const signUp = useSignUp();
  const login = useLogin();
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    gender: "Male",
    re_password: "",
  });

  const [isOpen, setIsOpen] = useState({
    signin: false,
    signup: false,
  });

  const closeModal = () => {
    setIsOpen({
      signin: false,
      signup: false,
    });
  };

  const handleAuthClick = (option) => {
    closeModal();
    if (option == "signin") {
      setIsOpen((prev) => ({ ...prev, signin: true }));
    } else if (option == "signup") {
      setIsOpen((prev) => ({ ...prev, signup: true }));
    } else return;
  };

  const [faq, setFaq] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
  });

  const handleFaqClick = useCallback((option) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      [option]: !prevFaq[option],
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleForgetModalSwitch = () => {
    closeModal();
    handleAuthClick("forget");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await signUp(formData);

      if (response?.status === 200 || response?.status === "success") {
        closeModal();
        setMenu(false);
        setRequest_id(response.request_id);
        setErrorMessage("");
        toastManager.addToast({
          message: `Account created succesfully. An OTP has been sent to your email`,
          type: "success",
        });
        handleAuthClick("otp");
        return;
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      setErrorMessage(error.response.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await login(formData.email, formData.password);
      if (response.status === 200 || response.status === "success") {
        setErrorMessage("");
        closeModal();
        setMenu(false);
        toastManager.addToast({
          message: "Successful login",
          type: "success",
        });
        return;
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      setErrorMessage(error.response.message);
    }
  };

  return (
    <div className="genre ">
      <div className="genre__section">
        <Header />
        <div className="baa">
          <section className="baa__section__one">
            <div className="baa__section__one__start">
              <h1>Become an Author</h1>
              <p>
                Join the community of authors, share your stories, and earn
                money for your work. Do eiusmod anim non ea in fugiat
                reprehenderit incididunt elit. Mollit pariatur ullamco est ut
                mollit. Sit mollit consectetur nulla eu qui laboris cubaaa amet
                exercitation veniam tempor quis in in. Cubaaa deserunt sit
                laboris pariatur id deserunt veniam sunt consequat id non
                ullamco amet reprehenderit. Amet ad Lorem esse excepteur.
              </p>
              <button
                onClick={() => {
                  auth.user ? navigate("/create") : handleAuthClick("signup");
                }}
              >
                Create Novel <FaPencilAlt />
              </button>
            </div>
            <div className="baa__section__one__end">
              <img src={baa} alt="become_author_banner" />
            </div>
          </section>
          <section className="baa__section__two"></section>
          <section className="baa__section__three">
            <span className="baa__section__three__start">
              <h3>FAQ</h3>
              <h1>
                Do you have another questions about BONREADER? Explore our
                Frequently Asked questions
              </h1>
            </span>
            <div>
              <div className="baa__faq">
                <div
                  className="baa__faq__question"
                  onClick={() => handleFaqClick("select1")}
                >
                  <h1>01</h1>
                  <span>
                    WHAT IS BONREADER ALL ABOUT?{" "}
                    <MdArrowOutward
                      className={`baa__arrow${faq.select1 ? " open" : ""}`}
                    />
                  </span>
                </div>
                <div
                  className={`baa__faq__answer${faq.select1 ? " open" : ""}`}
                >
                  <p>
                    Quis mollit consequat cupidatat in Lorem anim amet cubaaa
                    voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                    duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                    consequat esse cillum. Elit laboris tempor consectetur nulla
                    consectetur amet exercitation cillum mollit exercitation
                    elit aute veniam dolor.
                  </p>
                </div>
              </div>
              <div className="baa__faq">
                <div
                  className="baa__faq__question"
                  onClick={() => handleFaqClick("select2")}
                >
                  <h1>02</h1>
                  <span>
                    REQUIREMENTS TO BE AN AUTHOR?{" "}
                    <MdArrowOutward
                      className={`baa__arrow${faq.select2 ? " open" : ""}`}
                    />
                  </span>
                </div>
                <div
                  className={`baa__faq__answer${faq.select2 ? " open" : ""}`}
                >
                  <p>
                    Quis mollit consequat cupidatat in Lorem anim amet cubaaa
                    voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                    duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                    consequat esse cillum. Elit laboris tempor consectetur nulla
                    consectetur amet exercitation cillum mollit exercitation
                    elit aute veniam dolor.
                  </p>
                </div>
              </div>
              <div className="baa__faq">
                <div
                  className="baa__faq__question"
                  onClick={() => handleFaqClick("select3")}
                >
                  <h1>03</h1>
                  <span>
                    BENEFITS OF BEING AN AUTHOR?
                    <MdArrowOutward
                      className={`baa__arrow${faq.select3 ? " open" : ""}`}
                    />
                  </span>
                </div>
                <div
                  className={`baa__faq__answer${faq.select3 ? " open" : ""}`}
                >
                  <p>
                    Quis mollit consequat cupidatat in Lorem anim amet cubaaa
                    voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                    duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                    consequat esse cillum. Elit laboris tempor consectetur nulla
                    consectetur amet exercitation cillum mollit exercitation
                    elit aute veniam dolor.
                  </p>
                </div>
              </div>
              <div className="baa__faq">
                <div
                  className="baa__faq__question"
                  onClick={() => handleFaqClick("select4")}
                >
                  <h1>04</h1>
                  <span>
                    DURATION OF PAYMENT?
                    <MdArrowOutward
                      className={`baa__arrow${faq.select4 ? " open" : ""}`}
                    />
                  </span>
                </div>
                <div
                  className={`baa__faq__answer${faq.select4 ? " open" : ""}`}
                >
                  <p>
                    Quis mollit consequat cupidatat in Lorem anim amet cubaaa
                    voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                    duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                    consequat esse cillum. Elit laboris tempor consectetur nulla
                    consectetur amet exercitation cillum mollit exercitation
                    elit aute veniam dolor.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />

      {/* SIGNIN MODAL */}

      <PickTagsModal isOpen={isOpen.signin} onClose={closeModal}>
        <div className="modal__login__container">
          <img src={logo} alt="" />
          <h1>You are welcome</h1>
          <div className="modal__login__container__social__signin">
            <button>
              <img src={google__icon} alt="" />
              Sign in with Google
            </button>
            <button>
              <img src={facebook__icon} alt="" />
              Sign in with Facebook
            </button>
          </div>

          <form
            className="modal__login__container__form"
            onSubmit={handleLogin}
          >
            <input
              name="email"
              type="email"
              value={formData.email}
              important
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="password"
              type="password"
              value={formData.password}
              important
              placeholder="Enter your password"
              onChange={(e) => handleChange(e)}
            />
            {errorMessage && (
              <h3 className="header__error__message">{errorMessage}</h3>
            )}
            <button type="submit" disabled={auth.loading}>
              {auth.loading ? (
                <ClipLoader color="#fff" size={20} />
              ) : (
                " Sign In"
              )}
            </button>
            <p>
              Forgot password?{" "}
              <span onClick={handleForgetModalSwitch}>Reset Here</span>
            </p>
          </form>
          <p>
            Don't have an account?
            <span onClick={() => handleAuthClick("signup")}>Sign up</span>
          </p>
        </div>
      </PickTagsModal>

      {/* SIGNUP MODAL */}

      <PickTagsModal isOpen={isOpen.signu} onClose={closeModal}>
        <div className="modal__signup__container">
          <img src={logo} alt="" />
          <h1>Signup</h1>
          <div className="modal__signup__container__social__signin">
            <button>
              <img src={google__icon} alt="" />
              Sign in with Google
            </button>
            <button>
              <img src={facebook__icon} alt="" />
              Sign in with Facebook
            </button>
          </div>

          <form
            className="modal__signup__container__form"
            onSubmit={handleSubmit}
          >
            <input
              name="first_name"
              type="text"
              important
              placeholder="Enter First Name"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="last_name"
              type="text"
              important
              placeholder="Enter Last Name"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="email"
              type="email"
              important
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="password"
              type="password"
              important
              placeholder="Enter your password"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="re_password"
              type="password"
              important
              placeholder="Confirm password"
              onChange={(e) => handleChange(e)}
            />
            {errorMessage && (
              <h3 className="header__error__message">{errorMessage}</h3>
            )}
            <button type="submit" disabled={auth.loading}>
              {auth.loading ? <ClipLoader color="#fff" size={20} /> : "Sign up"}
            </button>
          </form>
          <p>
            Have an account?{" "}
            <span onClick={() => handleAuthClick("signin")}>Sign in</span>
          </p>
        </div>
      </PickTagsModal>
    </div>
  );
}

export default BeAnAuthor;
