import React, { HTMLProps, ReactNode, useState } from "react";

import { Link } from "react-router-dom";

import { ClipLoader } from "react-spinners";
export default function AppButton({
  children,
  onPress,
  className,
  loading = false,
  disabled = false,
  size,
  color,
  variant,
  href,
  ...props
}: {
  children: string | ReactNode;
  onPress?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | Promise<unknown>;
  loading?: boolean;
  size?: keyof typeof sizeClasses;
  color?: keyof typeof colorClasses;
  variant?: keyof typeof variantClasses;
  href?: string;
  // textColor?: string;
  // disabledColor?: string;
  disabled?: boolean;
  // fontSize?: number;
  className?: string;
  style?: HTMLProps<HTMLButtonElement>["style"];
}) {
  const [isLoading, setLoading] = useState(false);
  children =
    isLoading || loading ? (
      <ClipLoader className="text-inherit" size={props.size} />
    ) : (
      (children as ReactNode)
    );
  const Component = href ? Link : "button";
  return (
    <Component
      to={href!}
      onClick={
        onPress
          ? async (e: React.MouseEvent<HTMLElement>) => {
              if (disabled) return;
              console.log("Button click");
              if (isLoading) return;
              try {
                setLoading(true);
                await onPress(e);
              } finally {
                setLoading(false);
              }
            }
          : undefined
      }
      {...props}
      className={`${className || ""} app-button ${
        variantClasses[variant ?? "contained"]
      } ${colorClasses[color ?? "primary"]} ${sizeClasses[size ?? "medium"]}`}
    >
      {children}
    </Component>
  );
}

const colorClasses = {
  primary: "btn-color-primary",
  secondary: "btn-color-secondary",
  tertiary: "btn-color-tertiary",
  error: "btn-color-error",
};

const sizeClasses = {
  small: "btn-size-small",
  medium: "btn-size-medium",
  large: "btn-size-large",
};
const variantClasses = {
  contained: "btn-variant-contained",
  outlined: "btn-variant-outlined",
  text: "btn-variant-text",
};
