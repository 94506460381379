import { useMemo } from "react";
import { NovelChapter } from "../../../api";
import truncateDescription from "../../../utils/truncate";

export default function NovelChapterItem({
  chapter,
  index,
  onClick,
  isActive,
}: {
  chapter: NovelChapter;
  index: number;
  onClick: () => void;
  isActive: boolean;
}) {
  const date = useMemo(
    () => new Date(chapter.updated_at).toLocaleDateString(),
    [chapter.updated_at]
  );
  const time = useMemo(
    () => new Date(chapter.updated_at).toLocaleTimeString(),
    [chapter.updated_at]
  );
  return (
    <div
      key={index}
      onClick={onClick}
      className={`book__chapters__nav__chapters ${
        isActive ? " book__chapters__nav__chapters-active" : ""
      }`}
    >
      <h3>
        {`Chapter ${parseFloat(chapter.chapter)}: ${
          truncateDescription(chapter.title, 20) || "Untitled Chapter"
        }`}
      </h3>
      <p>
        {chapter.draft_state === "published" ||
        chapter.draft_state === "awaiting approval"
          ? `${chapter.words} Words | ${date} | ${time}`
          : "Unpublished"}
      </p>
    </div>
  );
}
