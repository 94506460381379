import { useEffect, useState } from "react";

import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header2/Header";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import "./Shelf.css";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/splash/loading/Loading";
import NoResult from "../../../components/splash/no-result/NoResult";
import { useAddToShelf, useShelf } from "../../../redux/actions/shelfActions";
import { useReading } from "../../../redux/actions/bookActions";
import truncateDescription from "../../../utils/truncate";
import toastManager from "../../../components/toast/ToasterManager";
import { ClipLoader } from "react-spinners";

function Shelf() {
  const addToShelf = useAddToShelf();
  const shelf = useShelf();
  const reading = useReading();
  const [shelfArray, setShelfArray] = useState([]);
  const [readingArray, setReadingArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingShelf, setLoadingShelf] = useState(false);
  const navigate = useNavigate();
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });

  const handleSelect = (option) => {
    setSelect((prevSelect) => ({
      ...prevSelect,
      select1: option === "select1",
      select2: option === "select2",
    }));
  };

  const handleShelf = async () => {
    try {
      setLoading(true);
      const response = await shelf();

      if (response.payload && Array.isArray(response.payload.results)) {
        setShelfArray(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch shelf");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReading = async () => {
    try {
      setLoading(true);
      const response = await reading();
      console.log("reading response", response);

      if (response.payload && Array.isArray(response.payload.results)) {
        setReadingArray(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch reading");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToShelf = async (id) => {
    try {
      setLoadingShelf(true);
      const response = await addToShelf(id);

      if (response.payload) {
        toastManager.addToast({
          message: "Novel added to shelf",
          type: "success",
        });
      } else {
        toastManager.addToast({
          message: "Error adding to shelf",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoadingShelf(false);
    }
  };

  useEffect(() => {
    handleShelf();
    handleReading();
  }, []);

  return (
    <div className="shelf">
      <div className="shelf__section">
        <Header />
        <div className="shelf__section__one">
          <div className="shelf__section__one__header">
            <h1>Shelf</h1>
            <span>
              <button
                onClick={() => handleSelect("select1")}
                className={`shelf__nav__button${
                  select.select1 ? " active" : ""
                }`}
              >
                Shelf
              </button>
              <button
                onClick={() => handleSelect("select2")}
                className={`shelf__nav__button${
                  select.select2 ? " active" : ""
                }`}
              >
                Reading
              </button>
            </span>
          </div>
          <div className="shelf__section__one__body">
            {select.select1 &&
              (loading ? (
                <Loading />
              ) : shelfArray.length === 0 ? (
                <NoResult />
              ) : (
                shelfArray.map((item, i) => (
                  <div
                    onClick={() => navigate(`/book/${item.novel.id}`)}
                    className="shelf__section__one__body__shelf"
                  >
                    <img
                      alt=""
                      src={
                        item?.novel?.cover_image
                          ? item.novel?.cover_image
                          : defaultBookCover
                      }
                    />
                    <h1>{item?.novel?.title}</h1>

                    {/* <span>
                      <p>Progress</p>
                      <p>{`${item?.progress}/${item?.novel?.num_chapters}`}</p>
                    </span> */}
                  </div>
                ))
              ))}

            {select.select2 &&
              (loading ? (
                <Loading />
              ) : readingArray.length === 0 ? (
                <NoResult />
              ) : (
                readingArray.map((item, i) => (
                  <div
                    key={i}
                    className="shelf__section__one__body__reading__block"
                  >
                    <img
                      alt="book cover"
                      src={
                        item.novel?.cover_image
                          ? item.novel.cover_image
                          : defaultBookCover
                      }
                    />
                    <div className="shelf__section__one__body__reading__block__end">
                      <h1>{item.novel.title}</h1>
                      <span className="shelf__section__one__body__reading__block__author">
                        {item.novel.author.name}
                      </span>
                      <span className="shelf__section__one__body__reading__block__genre">
                        {item.novel.genres.map((genre) => (
                          <p>{genre}</p>
                        ))}
                      </span>
                      <span className="shelf__section__one__body__reading__block__progress">
                        <p>Progress</p>
                        <p>{`${item.progress}/${item.novel.num_chapters}`}</p>
                      </span>
                      <span className="shelf__section__one__body__reading__block__details">
                        <p>
                          {item.novel.description &&
                            truncateDescription(item.novel.description, 80)}
                        </p>
                        <div>
                          {/* <button
                            onClick={() => handleAddToShelf(item.novel.id)}
                            disabled={loading}
                          >
                            {loadingShelf ? (
                              <ClipLoader color="#669933" size={20} />
                            ) : (
                              "ADD TO SHELF"
                            )}
                          </button> */}
                          <button
                            onClick={() => navigate(`/book/${item.novel.id}`)}
                          >
                            CONTINUE READING
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                ))
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shelf;
