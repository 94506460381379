import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ListResponse, Novel, NovelGenre } from "../../../api";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header2/Header";
import Loading from "../../../components/splash/loading/Loading";
import NoResult from "../../../components/splash/no-result/NoResult";
import truncateDescription from "../../../utils/truncate";
import "./Genre.css";

function Genre() {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedSort, setSelectedSort] = useState("popular");
  const [selectedGenre, setSelectedGenre] = useState("");
  const { data: { results: allGenres } = {}, isLoading: loadingGenres } =
    useQuery<ListResponse<NovelGenre>>({
      queryKey: ["genres/"],
      staleTime: 100000,
    });
  const { data: { results: novels } = {}, isLoading: novelsLoading } = useQuery<
    ListResponse<Novel>
  >({
    queryKey: [
      "novels/?",
      selectedGenre ? "&genre=" + selectedGenre : "",
      "&listing=" + selectedSort,
      "&completed=" +
        (selectedFilter === "ongoing"
          ? "False"
          : selectedFilter === "completed"
          ? "True"
          : ""),
    ],
  });

  const navigate = useNavigate();

  return (
    <div className="genre bg-bg">
      <div className="genre__section container pt-4 xl:pt-8">
        <Header />
        <section className="genre__section__one">
          <div className="genre__section__one__nav">
            <h1>Genre</h1>
            <div className="genre__section__one__nav__options__wrap">
              <div className="genre__section__one__nav__options">
                {loadingGenres ? (
                  <Loading />
                ) : (
                  [{ name: "" }, ...(allGenres ?? [])]?.map((genre, index) => (
                    <h3
                      onClick={() => setSelectedGenre(genre.name)}
                      className={`capitalize genre__section__one__nav__options__h3 ${
                        selectedGenre === (index === 0 ? "" : genre.name)
                          ? "active"
                          : ""
                      }`}
                      key={index}
                    >
                      {genre.name || "all"}
                    </h3>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="genre__section__one__body">
            <div className="genre__section__one__nav__options__wrap">
              <div className="genre__section__one__body__div1">
                <h1 className="whitespace-nowrap">Filter By</h1>
                <span>
                  <h1
                    onClick={() => setSelectedFilter("all")}
                    className={`${
                      selectedFilter === "all" ? "genre__nav__active" : ""
                    }`}
                  >
                    All
                  </h1>
                  <h1
                    onClick={() => setSelectedFilter("ongoing")}
                    className={`${
                      selectedFilter === "ongoing" ? "genre__nav__active" : ""
                    }`}
                  >
                    Ongoing
                  </h1>
                  <h1
                    onClick={() => setSelectedFilter("completed")}
                    className={`${
                      selectedFilter === "completed" ? "genre__nav__active" : ""
                    }`}
                  >
                    Completed
                  </h1>
                </span>
              </div>
            </div>

            <div className="genre__section__one__body__div1">
              <h1 className="whitespace-nowrap">Sort By</h1>
              <span>
                <h1
                  onClick={() => setSelectedSort("popular")}
                  className={`${
                    selectedSort === "popular" ? "genre__nav__active" : ""
                  }`}
                >
                  Popular
                </h1>
                <h1
                  onClick={() => setSelectedSort("editorialPicks")}
                  className={`${
                    selectedSort === "editorialPicks"
                      ? "genre__nav__active"
                      : ""
                  }`}
                >
                  Recommended
                </h1>
                <h1
                  onClick={() => setSelectedSort("topRanked")}
                  className={`${
                    selectedSort === "topRanked" ? "genre__nav__active" : ""
                  }`}
                >
                  Rating
                </h1>
              </span>
            </div>
            <div className="genre__section__one__body__display">
              {novelsLoading ? (
                <Loading />
              ) : !novels?.length ? (
                <NoResult />
              ) : (
                novels.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => navigate(`/book/${item.id}`)}
                      className="genre__section__one__body__display__block"
                    >
                      <img
                        className="object-cover"
                        alt=""
                        src={
                          item?.cover_image
                            ? item.cover_image
                            : defaultBookCover
                        }
                      />
                      <div className="genre__section__one__display__block__about">
                        <h1>{item.title && item.title}</h1>
                        <span>
                          <h1>{item.author && item.author.name}</h1>{" "}
                          <h2>
                            <FaEye />
                            {item.num_views && item.num_views}
                          </h2>
                        </span>
                        <p>
                          {item.description &&
                            truncateDescription(item.description, 90)}
                        </p>

                        <article>
                          {item.tags.map((item, i) => {
                            return <h1 key={i}>{item}</h1>;
                          })}
                        </article>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Genre;
