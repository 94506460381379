import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import Loading from "../components/splash/loading/Loading";

const AuthRoute = () => {
  const { isAuthorized } = useSelector((state) => state.auth);

  const isAuthenticated = isAuthorized;
  console.log({ isAuthenticated });

  return isAuthenticated === undefined ? (
    <Loading />
  ) : isAuthenticated ? (
    <Navigate to="/account-dashboard" />
  ) : (
    // && ADMIN_ROLES.includes(user.role)
    <Outlet />
  );
};

export default AuthRoute;
