import useWindowSize from "./useWindowSize";

export type ColumnMap<T> = {
  0: T;
  640: T;
  768: T;
  1024: T;
  1280: T;
  1536: T;
};

export function useResponsive<T>(columnMap: ColumnMap<T>) {
  const size = useWindowSize().width;
  return size < 640
    ? columnMap[0]
    : size < 768
    ? columnMap[640]
    : size < 1024
    ? columnMap[768]
    : size < 1280
    ? columnMap[1024]
    : size < 1536
    ? columnMap[1280]
    : columnMap[1536];
}
