import featureStyles from "./features.module.css";
export default function Features() {
  return (
    <div className="container -mt-24">
      <div
        className={`flex items-stretch justify-center lg:justify-between max-lg:flex-col rounded-[64px] py-8 px-4 sm:p-8 text-center max-lg:space-y-8 lg:space-x-16 ${featureStyles.container}`}
      >
        <div className="min-w-48 flex-grow basis-0 bg-white/60 py-4 px-4 rounded-xl">
          <h3 className={"text-white mb-0.5 " + featureStyles.textShadow}>
            Original Tales
          </h3>
          <p>Your Gateway to Infinite Stories</p>
        </div>
        <div className="min-w-48 flex-grow basis-0 bg-white/60 py-4 px-4 rounded-xl">
          <h3 className={"text-white mb-0.5 " + featureStyles.textShadow}>
            Become an Author
          </h3>
          <p>Earn as you create stories</p>
        </div>
        <div className="min-w-48 flex-grow basis-0 bg-white/60 py-4 px-4 rounded-xl">
          <h3 className={"text-white mb-0.5 " + featureStyles.textShadow}>
            Author's Manual
          </h3>
          <p>Step by step manual on how you can write top seling novels</p>
        </div>
      </div>
    </div>
  );
}
