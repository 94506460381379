import Modal from "../../../modal/Modal";
import { HeaderModalArgs } from "../Header";
import imageConfirmation from "../../../../assets/images/modal-confirmation.png";
export default function SubscriptionConfirmed({
  isOpen,
  closeModal,
}: HeaderModalArgs<"subscribed">) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="modal__confirmation__container">
        <img alt="Confirmation" src={imageConfirmation} />
        <h1>Subscription confirmed!</h1>
        <p>Thank you for subscribing to the weekly plan.</p>
        <p>
          Your subscription has been activated and you will receive an email
          confirmation soon.
        </p>
        <p>Enjoy you favourite stories!</p>
        <button onClick={() => closeModal()}>Continue to read</button>
      </div>
    </Modal>
  );
}
