import { useRef, useEffect } from "react";
import { FormInputProps, useField } from "./AppForm";
import "./form-components.css";
export default function FormTextArea({
  name,
}: FormInputProps & { label: string }) {
  const { value, update, error } = useField<string>(name);
  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (error) {
      ref.current?.setCustomValidity(error);
    } else {
      ref.current?.setCustomValidity("");
    }
  }, [error]);
  return (
    <span className="form__input__block">
      <label htmlFor={name}>Synopsis</label>
      <textarea
        ref={ref}
        required
        name={name}
        id={name}
        value={value}
        onChange={(e) => update(e.target.value)}
        className="form__input__block__textarea"
      ></textarea>
      {error && <span className="text-sm text-error">{error}</span>}
    </span>
  );
}
