import { useQuery } from "@tanstack/react-query";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NovelGenre, NovelTag, TagCategory } from "../../../api";
import Form, {
  FormComponentProps,
  FormContext,
  FormSubmit,
} from "../../../components/forms/AppForm";
import { FormErrorState } from "../../../components/forms/AppFormError";
import FormComponent from "../../../components/forms/FormComponent";
import FormImage from "../../../components/forms/FormImage";
import FormInput from "../../../components/forms/FormInput";
import FormSelect from "../../../components/forms/FormSelect";
import FormTextArea from "../../../components/forms/FormTextArea";
import toastManager from "../../../components/toast/ToasterManager";
import { createNovel } from "../../../services/bookServices";
import PickTagsModal from "./createNovelModal";
import parseTagsIntoCategories from "./createBookActions";

function CreateBook() {
  const navigate = useNavigate();

  const { data: { results: allGenres } = {} } = useQuery<{
    results: NovelGenre[];
  }>({
    queryKey: ["genres"],
  });
  const { data: { results: tagsArray } = {} } = useQuery<{
    results: NovelTag[];
  }>({
    queryKey: ["tags"],
  });

  const { data: { results: tagsCategories } = {} } = useQuery<{
    results: TagCategory[];
  }>({
    queryKey: ["tag-categories"],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const chipsData = useMemo(
    () => parseTagsIntoCategories(tagsCategories, tagsArray),
    [tagsArray, tagsCategories]
  );

  const handleConfirm = () => {
    setIsModalOpen(false);
  };

  const formRef = useRef<FormContext>(null!);
  return (
    <div className="rounded-2xl bg-white p-8 create_novel">
      <h1 className="font-brandon text-lg">Novel Information</h1>
      <div className="border-t border-t-gray-500 mt-2 pt-8">
        <section className="create__book">
          <Form
            formRef={formRef}
            onValidate={(data) => {
              const errors: FormErrorState["errors"] = {};
              if (!data.image) {
                errors.image = "Image is required";
              }
              if (!data.bookTitle) {
                errors.bookTitle = "Book Title is required";
              }
              if (!data.language) {
                errors.language = "Language is required";
              }
              if (data.description?.length < 150) {
                errors.description =
                  "Synopsis should be at least 150 characters long";
              }
              if (Object.keys(errors).length > 0) {
                return { errors, error: "One or more errors encountered." };
              }
              return null;
            }}
            initialValue={{
              image: null,
              bookTitle: "",
              language: "",
              leadingGender: "",
              genres: [],
              tags: [],
              description: "",
            }}
            onError={async (error) => {
              toastManager.addToast({
                message: error.error ?? "Please fix the errors and retry",
                type: "error",
              });
            }}
            onSubmit={async (data) => {
              try {
                const response = await createNovel(data);
                toastManager.addToast({
                  message: "Novel crated succesfully",
                  type: "success",
                });
                navigate(`/book-chapters/${response.id}`);
              } catch (error) {
                toastManager.addToast({
                  message:
                    ((error as any).response?.message ??
                      (error as any).message) ||
                    "Novel creation failed",
                  type: "error",
                });
              }
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formRef.current.submit();
              }}
            >
              <FormImage name="image" label="Book Cover" aspectRatio={3 / 4} />
              <FormInput name="bookTitle" label="Book Title" />
              <FormSelect
                name="language"
                label="Language"
                options={[{ value: "en", label: "English" }]}
              />
              <FormSelect
                name="leadingGender"
                label="Leading Gender"
                options={[
                  { value: "M", label: "Male" },
                  {
                    value: "F",
                    label: "Female",
                  },
                ]}
              />
              <FormSelect
                name="genres"
                label="Genres"
                options={
                  allGenres?.map((genre) => ({
                    value: genre.name,
                    label: genre.name,
                  })) ?? []
                }
              />

              {/* Display selected genres
              <div style={{ marginBottom: "20px" }}>
                {formData.genres.length > 0 ? (
                  <ul
                    style={{
                      listStyleType: "none",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {formData.genres.map((genre, index) => (
                      <li
                        key={index}
                        style={{
                          padding: "10px 15px",
                          borderRadius: "30px",
                          backgroundColor: "#66993322",
                          color: "#669933",
                          fontSize: "12px",
                        }}
                      >
                        {genre}
                      </li>
                    ))}
                  </ul>
                ) : null} */}
              {/* </div> */}
              <FormComponent name="tags">
                {
                  (({
                    update: setSelectedChips,
                    value: selectedChips,
                  }: Required<FormComponentProps<any[]>>) => {
                    return (
                      <div className="mb-4">
                        <label htmlFor="tags">Tags</label>
                        <button
                          type="button"
                          className="novel-modal-select-button"
                          onClick={() => setIsModalOpen(true)}
                        >
                          {selectedChips.length === 0
                            ? "Select Chips"
                            : selectedChips.map((chip) => {
                                return (
                                  <div key={chip.id} className="chip selected">
                                    {chip.label}
                                  </div>
                                );
                              })}
                        </button>
                        <PickTagsModal
                          sections={chipsData}
                          isOpen={isModalOpen}
                          onClose={() => setIsModalOpen(false)}
                          onSelectChip={(chip) =>
                            setSelectedChips(
                              selectedChips.includes(chip)
                                ? selectedChips.filter(
                                    (_chip) => _chip.id !== chip.id
                                  )
                                : [...selectedChips, chip]
                            )
                          }
                          selectedChips={selectedChips}
                          onConfirm={handleConfirm}
                        />
                      </div>
                    );
                  }) as any
                }
              </FormComponent>

              <FormTextArea name="description" label="Description" />
              <FormSubmit>Create Book</FormSubmit>
            </form>
          </Form>
        </section>
      </div>
    </div>
  );
}

export default CreateBook;
