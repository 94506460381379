import { useQuery } from "@tanstack/react-query";
import Loading from "../../splash/loading/Loading";
import { BiErrorCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Link } from "react-router-dom";
import { useState } from "react";
import AppButton from "../../AppButton";
import { useLogout } from "../../../redux/actions/authActions";

export default function ProfileButton({ onLogin }) {
  const isAuthenticated = useSelector((e: RootState) => e?.auth?.isAuthorized);
  const { data: user, status } = useQuery<any>({
    queryKey: ["/users/me"],
    enabled: isAuthenticated,
  });
  const logOut = useLogout();
  const [profileMenu, setShowProfileMenu] = useState(false);
  return (
    <>
      {profileMenu ? (
        <div
          className="bg-black/10 fixed top-0 left-0 w-screen h-screen"
          onClick={() => setShowProfileMenu(false)}
        ></div>
      ) : null}
      <button
        className="max-sm:hidden text-inherit relative translate-0 z-10 flex-shrink-0"
        onClick={
          isAuthenticated ? () => setShowProfileMenu(!profileMenu) : onLogin
        }
      >
        {isAuthenticated === undefined ? (
          <Loading />
        ) : isAuthenticated ? (
          status === "pending" ? (
            <Loading />
          ) : status === "error" ? (
            <BiErrorCircle className="text-error" />
          ) : (
            <img src={user.photo_url} className="h-8 w-8 rounded-full" />
          )
        ) : (
          "Login"
        )}
        {profileMenu ? (
          <div className="bg-white p-6 fixed z-40 right-0 shadow-2xl rounded-lg top-12 text-sm">
            <Link
              to="/shelf"
              className="whitespace-nowrap block py-2 mb-1 text-start min-w-72 border-b border-gray-200"
            >
              My Shelf
            </Link>
            <Link
              to="/account-dashboard"
              className="whitespace-nowrap block py-2 mb-1 text-start min-w-72 border-b border-gray-200"
            >
              Dashboard
            </Link>
            <AppButton
              variant="outlined"
              color="error"
              onPress={() => logOut()}
              className="whitespace-nowrap py-2 mt-4 min-w-72 capitalize font-brandon text-sm"
            >
              Log Out
            </AppButton>
          </div>
        ) : null}
      </button>
    </>
  );
}
