import React, {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FormErrorState } from "./AppFormError";
type NoInfer<T> = [T][T extends any ? 0 : never];

type PolymorphicComponentProps<
  C extends React.ElementType, // Base component type
  Props = object, // Other props for the generic component
  Exclude = object // Properties of the base component type that will be handled by this generic component
> = React.PropsWithChildren<
  Props & {
    as?: C;
  }
> &
  Omit<
    React.ComponentPropsWithoutRef<C>,
    keyof ({
      as?: C;
    } & Props &
      Exclude)
  >;

type Path = [...path: (string | number)[], key: string];
export type FormContext<T extends object = any> = {
  submit(): void;
  data: T;
  reset(value: any): void;
  update(path: Path, value: any): void;
  setError(e: FormErrorState): void;
} & FormErrorState;

const context = createContext(null! as FormContext);
const onErrorDefault = () => {};
export default function Form<T extends object = any>({
  onSubmit,
  formRef,
  children,
  initialValue,
  onValidate,
  onError = onErrorDefault,
  onChange,
}: {
  onSubmit?: (data: T) => void;
  onError?: (error: FormErrorState) => void;
  initialValue?: T;
  onChange?: (data: T) => void;
  onValidate?: (
    data: T
  ) => Promise<FormErrorState | null> | FormErrorState | null;
  formRef?: React.RefObject<FormContext<T>>;
} & PropsWithChildren) {
  const [data, setData] = useState<T>(initialValue ?? ({} as T));
  const [errorInfo, setErrorInfo] = useState<FormErrorState | null>(null);

  const callbacks = useRef({ onSubmit, onChange, onValidate, onError });
  callbacks.current.onChange = onChange;
  callbacks.current.onValidate = onValidate;
  callbacks.current.onSubmit = onSubmit;
  callbacks.current.onError = onError;

  const hasError = Boolean(errorInfo && (errorInfo.error || errorInfo.errors));
  useEffect(() => {
    if (callbacks.current.onValidate) {
      let cancelled = false;
      const deferred = setTimeout(async () => {
        if (callbacks.current.onValidate && hasError) {
          const result = await callbacks.current.onValidate(data);
          if (!cancelled) setErrorInfo(result);
        }
      }, 1000);
      return () => {
        cancelled = true;
        clearTimeout(deferred);
      };
    }
  }, [data, hasError]);
  const value = useMemo(() => {
    const setError = (e: FormErrorState | null) => {
      setErrorInfo(e);
      if (e) {
        callbacks.current.onError(e);
      }
    };
    return {
      data,
      setError,
      ...errorInfo,
      submit: async (e?: Event) => {
        if (e) e.preventDefault?.();
        const errorInfo = callbacks.current.onValidate
          ? await callbacks.current.onValidate(data)
          : null;
        setError(errorInfo);

        if (errorInfo) return;
        try {
          await callbacks.current.onSubmit?.(data);
        } catch (e) {
          console.error(e);
          setError({ error: e.message });
        }
      },
      update(path: Path, value: any) {
        const _isArray = Array.isArray;
        let x = _isArray(data) ? (data.slice() as T) : { ...data };
        const y = x;

        for (let i = 0; i < path.length - 1; i++) {
          const isArray = x[path[i]]
            ? _isArray(x[path[i]])
            : typeof path[i + 1] === "number";

          x = x[path[i]] = isArray
            ? [...(x[path[i]] || [])]
            : { ...(x[path[i]] || {}) };
        }

        if (value === undefined && _isArray(x)) {
          x.splice(path[path.length - 1] as number, 1);
        } else x[path[path.length - 1]] = value;
        setData(y);
        if (callbacks.current.onChange) {
          callbacks.current.onChange(y);
        }
      },
      reset: setData,
    };
  }, [data, errorInfo]);

  useEffect(() => {
    if (formRef) {
      formRef.current = value;
      return () => void (formRef.current = null!);
    }
  }, [formRef, value]);
  return <context.Provider value={value}>{children}</context.Provider>;
}
export const useField = function <T>(path_or_name: Path | string) {
  const path: Path = useMemo(() => {
    if (typeof path_or_name === "string")
      return path_or_name
        .split(/[[\].]/)
        .filter(Boolean)
        .map((e) => (/^\d+$/.test(e) ? parseInt(e) : e)) as Path;
    else return path_or_name ?? ["?"];
  }, [path_or_name]);
  const m = useContext(context);

  const error = path.reduce((acc, e) => acc?.[e], m.errors as unknown) as
    | string
    | undefined;
  return useMemo(
    () => ({
      value: path.reduce((acc, e) => acc?.[e], m.data) as T | undefined,
      error: Array.isArray(error) ? error.join(". ") + "." : error,
      update(value: T) {
        m.update(path, value);
      },
    }),
    [...path, m]
  );
};
export type FormComponentProps<T = string> = Partial<
  ReturnType<typeof useField<T>>
>;
export type IFormElement<T> = React.ElementType<FormComponentProps<T>>;

export type FormInputProps = {
  error?: string;
  placeholder?: string;
  required?: boolean;
  name: string;
};

export function FormArray<T = string>({
  name,
  renderEach,
  renderHeader,
  renderFooter,
  ...props
}: {
  renderEach: (
    value: T,
    i: number,
    update: (value: T | undefined) => void
  ) => ReactNode;
  renderHeader?: (value: T[], update: (value: T[]) => void) => ReactNode;
  renderFooter?: (value: T[], update: (value: T[]) => void) => ReactNode;
} & PolymorphicComponentProps<
  React.ElementType<any, "div">,
  FormInputProps,
  FormComponentProps<T[]>
>) {
  const field = useField<T[]>(name);
  if (!field.value) field.value = [];
  return (
    <>
      {renderHeader ? renderHeader(field.value, field.update) : null}
      {field.value.map((e, i) => {
        return renderEach(e, i, (value: T | undefined) => {
          /** This matches the behaviour of Form.update */
          field.update(
            value === undefined
              ? [...field.value!.slice(0, i), ...field.value!.slice(i + 1)]
              : [
                  ...field.value!.slice(0, i),
                  value,
                  ...field.value!.slice(i + 1),
                ]
          );
        });
      })}
      {renderFooter ? renderFooter(field.value, field.update) : null}
    </>
  );
}

export function FormSubmit(
  props: PolymorphicComponentProps<
    React.ElementType<any, "button">,
    { onPress?: any }
  >
) {
  const form = useContext(context);
  return <button onClick={form.submit} {...props} />;
}
