import { DependencyList, useState, useCallback } from "react";
import api from "../../../api/axios";
import toastManager from "../../../components/toast/ToasterManager";
import { MyNovelChapter, Novel, NovelChapter } from "../../../api";
import { useQueryClient } from "@tanstack/react-query";

function useAsyncCallback(f: () => Promise<any>, deps: DependencyList) {
  const [loading, setLoading] = useState(false);
  return [
    loading,
    useCallback(async () => {
      setLoading(true);
      try {
        await f();
      } catch (e) {
        toastManager.addToast({ type: "error", message: (e as any).message });
      }
      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps),
  ] as const;
}

export const useCreateNovelChapter = (
  novelId: Novel["id"],
  updateChapter: (chapter: MyNovelChapter) => void
) =>
  useAsyncCallback(async () => {
    const response = await api.request({
      url: "/novels/mine/" + novelId + "/chapters/",
      method: "POST",
    });
    updateChapter(response.data);
  }, [novelId]);

export const usePublishing = (
  novelId: Novel["id"],
  chapter: NovelChapter,
  title: string,
  content: string
) => {
  const client = useQueryClient();
  return useAsyncCallback(async () => {
    const url =
      "/novels/mine/" +
      novelId +
      "/chapters/" +
      chapter?.chapter +
      "/" +
      "request-publish/";

    await api.request({
      url,
      method: "POST",
      data: {
        draft: content,
        title: title,
      },
    });
    client.invalidateQueries({
      queryKey: ["novels/mine/", novelId, "/chapters/"],
    });
    toastManager.addToast({
      type: "success",
      message: "Chapter sent for approval",
    });
  }, [content, chapter?.chapter, novelId, title]);
};

export const useSaveAsDraft = (
  novelId: Novel["id"],
  chapter: NovelChapter,
  title: string,
  content: string,
  updateChapter: (chapter: MyNovelChapter) => void
) => {
  const client = useQueryClient();
  return useAsyncCallback(async () => {
    const url =
      "/novels/mine/" +
      novelId +
      "/chapters/" +
      (chapter?.chapter ? chapter?.chapter + "/" : "") +
      "draft/";
    const response = await api.request({
      url,
      method: chapter?.chapter ? "PATCH" : "",
      data: {
        draft: content,
        title: title,
      },
    });
    client.invalidateQueries({
      queryKey: ["novels/mine/", novelId, "/chapters/"],
    });

    toastManager.addToast({
      type: "success",
      message: "Chapter has been saved",
    });
    updateChapter({ ...chapter, ...response.data });
  }, [content, chapter?.chapter, novelId, title]);
};
