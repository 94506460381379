import { useLocation, useNavigate } from "react-router-dom";
import "./view-novels.css";
import Header from "../../../components/layout/header2/Header";
import Footer from "../../../components/layout/footer/Footer";
import { FaArrowLeft, FaEye } from "react-icons/fa";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import truncateDescription from "../../../utils/truncate";

function ViewNovels() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const content = state.content;

  return (
    <div className="view__novels">
      <section className="view__novels__section__one">
        <Header />
      </section>
      <section className="view__novels__section__two">
        <div className="view__models__section__title">
          <h1>
            <FaArrowLeft
              onClick={() => navigate(-1)}
              className="view__novels__section__title__arrow"
            />{" "}
            {state?.type}
          </h1>
        </div>
        <div className="genre__section__one__body__display">
          {content?.map(function (item, i) {
            // Check if the index is less than six
            if (i < 10) {
              return (
                <div
                  onClick={() => navigate(`/book/${item.id}`)}
                  className="genre__section__one__body__display__block"
                >
                  <img
                    alt=""
                    src={
                      item?.cover_image ? item.cover_image : defaultBookCover
                    }
                  />
                  <div className="genre__section__one__display__block__about">
                    <h1>{item?.title}</h1>
                    <span>
                      <h1>{item?.author?.name || "Unknown Author"}</h1>{" "}
                      <h2>
                        <FaEye /> {` ${item?.num_views || 0} `}
                      </h2>
                    </span>
                    <p>
                      {item.description &&
                        truncateDescription(item.description, 90)}
                    </p>
                    <span>
                      <h1>{item?.genres?.[0] || "Unknown Genre"}</h1>
                      {/* <h1>Crime</h1> */}
                    </span>
                  </div>
                </div>
              );
            }
            // If index is greater than or equal to six, return null
            return null;
          })}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ViewNovels;
