import { FormEvent } from "react";
import { ClipLoader } from "react-spinners";
import logo from "../../../../assets/icons/logo.png";
import Modal from "../../../modal/Modal";
import {
  useAuth,
  useResetPassword,
} from "../../../../redux/actions/authActions";
import { HeaderModalArgs } from "../Header";

export default function NewPassword({
  isOpen,
  closeModal,
  token,
}: HeaderModalArgs<"new-password">) {
  const auth = useAuth();
  const errorMessage = auth.error;
  const updatePassword = useResetPassword();
  const handleReset = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    updatePassword(
      (
        (ev.target as HTMLFormElement).elements.namedItem(
          "password"
        ) as HTMLInputElement
      ).value,
      token
    );
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="modal__login__container">
        <img src={logo} alt="" />
        <h1>Enter New Password</h1>
        <h4 className="otp__form__text">
          Enter a new password different from previously used passwords
        </h4>

        <form className="modal__login__container__form" onSubmit={handleReset}>
          <input
            type="password"
            name="password"
            required
            placeholder="Enter password"
          />
          <input
            type="password"
            name="confirm-password"
            required
            placeholder="Confirm password"
          />

          {errorMessage && (
            <h3 className="login__error__message">{errorMessage}</h3>
          )}
          <button type="submit" disabled={auth.loading}>
            {auth.loading ? (
              <ClipLoader color="#fff" size={20} />
            ) : (
              "Reset Password"
            )}
          </button>
        </form>
      </div>
    </Modal>
  );
}
