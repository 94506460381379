import { BrowserRouter, Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
import { useSelector } from "react-redux";
import api from "./api/axios";
import "./App.css";
import ToasterContainer from "./components/toast/ToasterContainer";
import ScrollToTopIcon from "./components/ui/scroll-to-top-icon/ScrollToTopIcon";
import ForgetPassword from "./pages/auth/forget-password/ForgetPassword";
import Login from "./pages/auth/login/Login";
import OTP from "./pages/auth/otp/OTP";
import ResetPassword from "./pages/auth/reset-password/ResetPassword";
import Signup from "./pages/auth/signup/Signup";
import SuccesfulReset from "./pages/auth/successful-reset/SuccessfulReset";
import AccountDashboard from "./pages/main/account.dashboard/Account.dashboard";
import AccountInbox from "./pages/main/account.inbox/AccountInbox";
import AccountProfile from "./pages/main/account.profile/Account.profile";
import AccountStories from "./pages/main/account.stories/Account.stories";
import AccountTransactionHistory from "./pages/main/account.transaction.history/AccountTransactionHistory";
import Account from "./pages/main/account/Account";
import AccountIncome from "./pages/main/acoount.income/Account.income";
import BookChapters from "./pages/main/book-chapters/BookChapters";
import BookPage from "./pages/main/book-page/BookPage";
import Book from "./pages/main/book/Book";
import Contract from "./pages/main/contract/Contract";
import CreateBook from "./pages/main/create-book/CreateBook";
import Create from "./pages/main/create/Create";
import EditBook from "./pages/main/edit-book/EditBook";
import Genre from "./pages/main/genre/Genre";
import Home from "./pages/main/home/Home";
import Ranking from "./pages/main/ranking/Ranking";
import Shelf from "./pages/main/shelf/Shelf";
import ViewNovels from "./pages/main/view-novels/ViewNovels";
import BeAnAuthor from "./pages/supplementary/beAnAuthor/beAnAuthor";
import Contact from "./pages/supplementary/contact/Contact";
import NotFound from "./pages/supplementary/not-found/notFound";
import Privacy from "./pages/supplementary/privacy/Privacy";
import Terms from "./pages/supplementary/terms/Terms";
import { RootState } from "./redux/store";
import AuthRoute from "./route/authRoute";
import PrivateRoute from "./route/privateRoute";
import PublicRoute from "./route/publicRoute";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  const isAuthorized = useSelector(({ auth }: RootState) => auth.isAuthorized);
  const [client] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            async queryFn({ queryKey, meta }) {
              if (meta?.authorize && !isAuthorized) {
                throw new Error("Skipping request as user is logged out!!");
              }
              const { data } = await api.get(queryKey.join(""));
              return data;
            },
            // persister: createSyncStoragePersister({
            //   storage: window.localStorage,
            // })
          },
        },
      })
  );

  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <ToasterContainer />
          <ScrollToTopIcon />
          <ScrollToTop />
          <Routes>
            {/* PUBLIC ROUTES */}

            <Route path="/" element={<AuthRoute />}>
              <Route path="signin" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="forget-password" element={<ForgetPassword />} />
              <Route path="successful-reset" element={<SuccesfulReset />} />
              <Route path="otp" element={<OTP />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              <Route index element={<Home />} />
              <Route path="genre" element={<Genre />} />
              <Route path="ranking" element={<Ranking />} />
              <Route path="shelf" element={<Shelf />} />
              <Route path="book/:id" element={<Book />} />
              <Route path="book-page/:id" element={<BookPage />} />
              <Route path="book-chapters/:id" element={<BookChapters />} />
              <Route path="create" element={<Create />} />
              <Route path="book-chapters" element={<BookChapters />} />
              <Route path="view-novels" element={<ViewNovels />} />
              <Route path="be-an-author" element={<BeAnAuthor />} />
              <Route path="terms-of-use" element={<Terms />} />
              <Route path="contact" element={<Contact />} />
              <Route path="privacy-policy" element={<Privacy />} />
            </Route>
            {/* PRIVATE ROUTES */}
            <Route element={<PrivateRoute />}>
              <Route path="create-book" element={<CreateBook />} />
              <Route element={<Account />}>
                <Route
                  path="/account-dashboard"
                  element={<AccountDashboard />}
                />
                <Route path="/account-stories" element={<AccountStories />} />
                <Route path="/account-profile" element={<AccountProfile />} />
                <Route path="/account-edit-book/:id" element={<EditBook />} />
                <Route path="/account-inbox" element={<AccountInbox />} />
                <Route path="/account-income" element={<AccountIncome />} />
                <Route path="/contract" element={<Contract />} />

                <Route path="/create-novel" element={<CreateBook />} />
                <Route
                  path="/account-transaction-history"
                  element={<AccountTransactionHistory />}
                />
              </Route>
            </Route>

            {/* CATCH ALL ROUTE */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
