import { useEffect, useRef } from "react";
import { FormInputProps, useField } from "./AppForm";
import "./form-components.css";

export default function FormInput({
  name,
  placeholder,
  required = true,
  type = "text",
  label,
}: FormInputProps & { type?: string; label: string }) {
  const { value, update, error } = useField<string>(name);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (error) {
      ref.current?.setCustomValidity(error);
    } else {
      ref.current?.setCustomValidity("");
    }
  }, [error]);
  return (
    <div className="form__input__block">
      <label htmlFor={name}>{label}</label>
      <input
        ref={ref}
        name={name}
        value={value}
        id={name}
        type={type}
        required={required}
        onChange={(e) => update(e.target.value)}
        placeholder={placeholder}
        className="invalid:border-error "
      />
      {error && <span className="text-sm text-error">{error}</span>}
    </div>
  );
}
