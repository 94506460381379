import { BiCategory, BiEdit, BiHome, BiSearch } from "react-icons/bi";
import { MdOutlineLeaderboard, MdTranslate } from "react-icons/md";
import { PiBooks } from "react-icons/pi";
import { Link } from "react-router-dom";
export default function MobileMenu({
  showMenu = false,
}: {
  showMenu: boolean;
}) {
  return (
    <>
      <div
        className={
          (showMenu ? "fixed" : "hidden") +
          " bg-white right-0 top-10 z-50 max-sm:min-w-[calc(100vw-64px)] sm:min-w-sm md:min-w-md p-4 w-lg max-w-4/5 rounded-2xl shadow-lg border border-primary-accent/20 text-start capitalize"
        }
      >
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/"
        >
          <BiHome className="inline-block align-bottom mb-1 mr-2" /> Home
        </Link>
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/genre"
        >
          <BiCategory className="inline-block align-bottom mb-1 mr-2" /> Genre
        </Link>
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/ranking"
        >
          <MdOutlineLeaderboard className="inline-block align-bottom mb-1 mr-2" />{" "}
          Ranking
        </Link>
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/create"
        >
          <BiEdit className="inline-block align-bottom mb-1 mr-2" /> Create
        </Link>
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/shelf"
        >
          <PiBooks className="inline-block align-bottom mb-1 mr-2" /> Shelf
        </Link>
        <div className="" />
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/shelf"
        >
          <MdTranslate className="inline-block align-bottom mb-1 mr-2" />{" "}
          English
        </Link>
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/search"
        >
          <BiSearch className="inline-block align-bottom mb-1 mr-2" /> Search
        </Link>
        <Link
          className="hover:text-primary-accent font-brandon block pb-4"
          to="/account-dashboard"
        >
          <BiSearch className="inline-block align-bottom mb-1 mr-2" /> Dashboard
        </Link>
      </div>
    </>
  );
}
