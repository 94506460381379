import { useQuery } from "@tanstack/react-query";
import homeSectionOneImage from "../../../assets/images/home__section__one__bg.png";
import { ListResponse, Novel } from "../../../api";
import { useMemo } from "react";
import AppButton from "../../../components/AppButton";
export default function Landing() {
  const { data: { results: novels } = {} } = useQuery<ListResponse<Novel>>({
    queryKey: ["/novels/", "?listing=powerRanking"],
  });
  const seed = useMemo(() => {
    return novels ? Math.floor(Math.random() * novels.length) : 0;
  }, [novels]);
  const featured = novels?.[seed];

  return (
    <div className="demo min-h-[480px] flex flex-row flex-wrap  mt-8 gap-8 sm:gap-16 pb-32">
      <div className="demo flex-grow basis-sm py-8 max-lg:text-center">
        <span className="inline-block border-tertiary border-2 py-1 px-2 rounded-3xl text-tertiary uppercase">
          Rank #{seed + 1}
        </span>
        <h1 className="uppercase text-5xl mt-2 max-w-md leading-snug font-extrabold max-lg:text-center max-lg:mx-auto">
          {featured?.title}
        </h1>
        {featured ? (
          <div className="uppercase font-bold mt-1">
            <span>{featured.genres[0]}</span>
            <span>{featured.author.name}</span>
          </div>
        ) : null}
        <p className="font-light text-lg mt-2 max-h-40 min-h-30 line-clamp-6 overflow-ellipsis overflow-hidden">
          {featured?.description}
        </p>
        <AppButton color="tertiary" className="mt-6 px-8">
          Read Now
        </AppButton>
      </div>
      <div className="demo flex-grow basis-sm h-auto">
        <div className="w-full h-full max-w-md max-h-md relative mx-auto">
          <img
            src={homeSectionOneImage}
            className="w-full h-full object-cover rounded-2xl"
          />
          <img
            src={featured?.cover_image}
            className="w-48 aspect-[3/4] rounded-xl object-cover absolute top-1/2 left-1/2 -mt-32 -ml-24 border-white border-2 bg-black"
          />
        </div>
      </div>
    </div>
  );
}
