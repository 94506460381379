import Modal from "../../../modal/Modal";
import { HeaderModalArgs } from "../Header";
import logo from "../../../../assets/icons/logo.png";
import {
  useAuth,
  usePasswordForget,
  usePasswordResetComplete,
  useVerifyEmail,
} from "../../../../redux/actions/authActions";
import { FormEvent, useCallback } from "react";
import { MdEmail } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import api from "../../../../api/axios";

export default function EnterCode({
  isOpen,
  closeModal,
  mode,
  email,
  request_id,
}: HeaderModalArgs<"code">) {
  const completePasswordReset = usePasswordResetComplete();
  const handleVerify = useVerifyEmail();

  const errorMessage = useAuth().error;
  const auth = useAuth();
  const onPasswordForget = usePasswordForget();
  const handleResend = useCallback(async () => {
    let results: any;
    if (mode === "confirm-email") {
      results = (
        await api.post("/auth/resend-verify-email/", {
          email,
        })
      ).data;
    } else {
      results = await onPasswordForget(email);
    }
    closeModal("code", { email, mode, request_id: results.request_id });
  }, [closeModal, onPasswordForget, email, mode]);
  const handleSubmit = useCallback(
    (ev: FormEvent<unknown>) => {
      const data = new FormData(ev.target as HTMLFormElement);
      const email = data.get("email");
      const code = data.get("code");
      if (mode === "confirm-email") {
        handleVerify(email, request_id, code);
      } else {
        completePasswordReset(email, request_id, code);
      }
    },
    [handleVerify, completePasswordReset, mode, request_id]
  );
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="modal__login__container">
        <img src={logo} alt="" />
        <h1>Enter OTP</h1>
        <h4 className="otp__form__text">
          A code has been sent to your mail, check and input code below to
          continue
        </h4>

        <form className="modal__login__container__form" onSubmit={handleSubmit}>
          <input type="number" name="code" placeholder="Enter otp" />

          {errorMessage && (
            <h3 className="login__error__message">{errorMessage}</h3>
          )}
          <button type="submit" disabled={auth.loading}>
            {auth.loading ? (
              <ClipLoader color="#fff" size={20} />
            ) : (
              "Verify and Proceed"
            )}
          </button>
          <p>
            Didn't get a code? <span onClick={handleResend}>Resend</span>
          </p>
        </form>
      </div>
    </Modal>
  );
}
