import { FormEvent, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import facebook__icon from "../../../../assets/icons/facebook.png";
import google__icon from "../../../../assets/icons/google.png";
import logo from "../../../../assets/icons/logo.png";
import {
  useAuth,
  useLogin,
  useSignUp,
} from "../../../../redux/actions/authActions";
import Modal from "../../../modal/Modal";
import { HeaderModalArgs } from "../Header";
import "../../../modal-children-style/modal-login.css";

export default function Login({
  isOpen,
  closeModal,
  isSignUp = false,
}: HeaderModalArgs<"login"> & { isSignUp?: boolean }) {
  const doLogin = useLogin();
  const doSignUp = useSignUp();
  const auth = useAuth();
  const handleLogin = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const data = new FormData(ev.target as HTMLFormElement);
    if (isSignUp)
      doSignUp(
        data.get("email"),
        data.get("password"),
        data.get("first_name"),
        data.get("last_name")
      );
    else doLogin(data.get("email"), data.get("password"));
  };
  useEffect(() => {
    if (auth.isAuthorized) closeModal();
  }, [auth.isAuthorized, closeModal]);
  const errorMessage = auth?.error;

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className="bg-bg!">
      <div className="modal__login__container -mt-12">
        <img src={logo} alt="" />
        <h1>You are welcome</h1>
        <div className="modal__login__container__social__signin max-w-full gap-y-4">
          <button className="bg-white border! border-[#deded5]! py-2 leading-snug! grow-1">
            <img src={google__icon} alt="" />
            Sign in with Google
          </button>
          <button className="bg-white border! border-[#deded5]! py-2 leading-snug! grow-1">
            <img src={facebook__icon} alt="" />
            Sign in with Facebook
          </button>
        </div>

        <form
          className="modal__login__container__form max-w-full"
          onSubmit={handleLogin}
        >
          {isSignUp ? (
            <>
              <input
                name="first_name"
                type="text"
                required
                placeholder="Enter First Name"
              />
              <input
                name="last_name"
                type="text"
                required
                placeholder="Enter Last Name"
              />
              <input
                name="email"
                type="email"
                required
                placeholder="Enter your email address"
              />
              <input
                name="password"
                type="password"
                required
                placeholder="Enter your password"
              />
              <input
                name="re_password"
                type="password"
                required
                placeholder="Confirm password"
              />
            </>
          ) : (
            <>
              <input
                name="email"
                type="email"
                required
                placeholder="Enter your email address"
              />
              <input
                name="password"
                type="password"
                required
                placeholder="Enter your password"
              />
            </>
          )}
          {errorMessage && (
            <h3 className="header__error__message text-red-600 text-sm font-normal">
              {errorMessage}
            </h3>
          )}
          <button type="submit" disabled={auth.loading} className="max-w-full">
            {auth.loading ? <ClipLoader color="#fff" size={20} /> : " Sign In"}
          </button>
          <p>
            Forgot password?{" "}
            <span onClick={() => closeModal("reset-password")}>Reset Here</span>
          </p>
        </form>
        <p>
          Don't have an account?
          <span onClick={() => closeModal("signup")}>Sign up</span>
        </p>
      </div>
    </Modal>
  );
}
