import { BiCategory, BiEdit, BiHome, BiSearch } from "react-icons/bi";
import { MdOutlineLeaderboard, MdTranslate } from "react-icons/md";
import { PiBooks } from "react-icons/pi";
import { Link } from "react-router-dom";
import logo from "../../../assets/icons/logo.png";
import ProfileButton from "./ProfileButton";
import { FaBars } from "react-icons/fa";
import AppButton from "../../AppButton";
import { useCallback, useState } from "react";
import Login from "./modals/Login";
import ChoosePlan from "./modals/ChoosePlan";
import EnterCode from "./modals/EnterCode";
import GetCoin from "./modals/GetCoin";
import NewPassword from "./modals/NewPassword";
import RequestResetPassword from "./modals/RequestResetPassword";
import SignUp from "./modals/SignUp";
import SubscriptionConfirmed from "./modals/SubscriptionConfirmed";
import MobileMenu from "./MobileMenu";

export type HeaderModalType =
  | "plan"
  | "code"
  | "coin"
  | "login"
  | "new-password"
  | "reset-password"
  | "signup"
  | "subscribed";
type HeaderModalExtraArgs<T extends HeaderModalType = HeaderModalType> =
  T extends "code"
    ? {
        request_id: string;
        mode: "confirm-email" | "reset-password";
        email: string;
      }
    : T extends "subscribed"
    ? { plan: string }
    : T extends "new-password"
    ? {
        token: string;
      }
    : object;
export type HeaderModalArgs<T extends HeaderModalType> = {
  isOpen: boolean;
  closeModal: <T extends HeaderModalType = HeaderModalType>(
    ...args: [modal: T, args?: HeaderModalExtraArgs<T>] | []
  ) => void;
} & HeaderModalExtraArgs<T>;
export default function Header() {
  const [showMenu, setMenu] = useState(false);
  const [modal, showModal] = useState<
    [modal: HeaderModalType, args?: any] | null
  >(null);
  const closeModal = useCallback((...args: typeof modal & any[]) => {
    showModal(args);
  }, []);

  return (
    <>
      {showMenu ? (
        <div
          className="bg-black/30 absolute inset-0 z-20"
          onClick={() => setMenu(false)}
        ></div>
      ) : null}
      <div className="bg-white relative z-20 rounded-3xl py-2 px-4 my-4 flex gap-x-4 xl:gap-x-8 items-center justify-between pr-4 md:pr-6 2xl:pr-8">
        <Link className="xl:pr-12 flex-shrink-0" to="/">
          <img src={logo} className="h-8 object-contain object-left " />
        </Link>
        <div className="flex-1 lg:hidden"></div>

        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/"
        >
          <BiHome className="inline-block align-bottom mb-1 mr-1" /> Home
        </Link>
        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/genre"
        >
          <BiCategory className="inline-block align-bottom mb-1 mr-1" />
          Genre
        </Link>
        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/ranking"
        >
          <MdOutlineLeaderboard className="inline-block align-bottom mb-1 mr-1" />{" "}
          Ranking
        </Link>
        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/create"
        >
          <BiEdit className="inline-block align-bottom mb-1 mr-1" /> Create
        </Link>
        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/shelf"
        >
          <PiBooks className="inline-block align-bottom mb-1 mr-1" /> Shelf
        </Link>
        <div className="max-lg:hidden xl:flex-grow" />
        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/shelf"
        >
          <BiSearch />
        </Link>

        <Link
          className="hover:text-primary-accent font-bold max-lg:hidden whitespace-nowrap"
          to="/shelf"
        >
          <MdTranslate className="inline-block align-bottom mb-1 mr-1" />{" "}
          English
        </Link>

        <ProfileButton onLogin={() => closeModal("login")} />
        <div className="lg:hidden" />
        <AppButton
          variant="text"
          className="text-black px-2 mx-0 lg:hidden relative translate-0"
          onPress={() => {
            setMenu(!showMenu);
          }}
        >
          <FaBars />
          <MobileMenu showMenu={showMenu} />
        </AppButton>
      </div>
      <Login
        isOpen={modal?.[0] === "login"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <ChoosePlan
        isOpen={modal?.[0] === "plan"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <EnterCode
        isOpen={modal?.[0] === "code"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <GetCoin
        isOpen={modal?.[0] === "coin"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <NewPassword
        isOpen={modal?.[0] === "new-password"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <RequestResetPassword
        isOpen={modal?.[0] === "reset-password"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <SignUp
        isOpen={modal?.[0] === "signup"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
      <SubscriptionConfirmed
        isOpen={modal?.[0] === "subscribed"}
        closeModal={closeModal}
        {...(modal?.[1] ?? null)}
      />
    </>
  );
}
