import Modal from "../../../modal/Modal";
import { HeaderModalArgs } from "../Header";
import coin from "../../../../assets/images/book-gift.png";
import { useCallback } from "react";

export default function GetCoin({
  isOpen,
  closeModal,
}: HeaderModalArgs<"coin">) {
  const handleGetCoin = useCallback(() => {
    //TODO - Handle Get Coin
  }, []);
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="ac__modal__buy">
        <h1>TOP UP COINS</h1>
        <p>Coins can be used to unlock chapters and buy gifts for authors.</p>
        <div className="ac__modal__buy__options">
          <div className="ac__modal__buy__option">
            <span>
              <p>100</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
          <div className="ac__modal__buy__option">
            <span>
              <p>300</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
          <div className="ac__modal__buy__option">
            <span>
              <p>500</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
          <div className="ac__modal__buy__option">
            <span>
              <p>1000</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
          <div className="ac__modal__buy__option">
            <span>
              <p>2000</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
          <div className="ac__modal__buy__option">
            <span>
              <p>5000</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
          <div className="ac__modal__buy__option">
            <span>
              <p>10000</p>
              <img src={coin} alt="coin" />
            </span>
            <h3>$0.99</h3>
          </div>
        </div>
        <button onClick={handleGetCoin}> PAY NOW </button>
      </div>
    </Modal>
  );
}
