import { Keyboard } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import popularNovelStyles from "./popular-novels.module.css";
// @ts-expect-error - Import swiper css
import "swiper/css";
// @ts-expect-error - Import swiper css
import "swiper/css/keyboard";

import { useQuery } from "@tanstack/react-query";
import { ListResponse, Novel } from "../../../api";
import { useResponsive } from "../../../common/utils/useResponsive";
import { useRef, useState } from "react";
import type { Swiper as SwiperType } from "swiper/types";

export default function PopularNovels() {
  const { data: { results: novels } = {} } = useQuery<ListResponse<Novel>>({
    queryKey: ["/novels/", "?listing=popular"],
  });
  const count = useResponsive({
    "0": 3,
    "640": 3,
    "768": 3,
    "1024": 3,
    "1280": 3,
    "1536": 3,
  });

  const ref = useRef<SwiperType>(null);
  const [active, setActive] = useState(1);

  return (
    <div
      className={
        "rounded-4xl px-2 sm:px-8 py-4 mt-8 max-w-full " +
        popularNovelStyles.container
      }
    >
      <div className="rounded-2xl bg-white/75 py-2 px-4 w-sm mx-auto 2xl:w-md max-lg:w-md max-w-full">
        <Swiper
          key={count}
          modules={[Keyboard]}
          keyboard
          spaceBetween={0}
          initialSlide={active}
          centeredSlides
          speed={300}
          slidesPerView={count}
          onSlideChange={(swiper) => setActive([1, 0, 2][swiper.activeIndex])}
          onSwiper={(swiper) => (ref.current = swiper)}
        >
          {[1, 0, 2].map((e, i) => (
            <SwiperSlide
              key={e}
              className={"py-4 md:py-8 " + popularNovelStyles.zoomOnActive}
              onClick={() => {
                ref.current?.slideTo(i);
              }}
            >
              <img
                className={"w-full novel-cover " + popularNovelStyles.zoom}
                src={novels?.[e].cover_image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="border-t border-gray-600 my-4" />
        <h3 className="text-center">{novels?.[active].title}</h3>
        <div className="flex overflow-hidden">
          {[0, 1, 2].map((e) => (
            <div
              className={
                "w-full shrink-0 " + (e === active ? "" : "invisible order-1")
              }
            >
              <div className="text-center" key={e}>
                <span className="text-primary-accent capitalize">
                  {novels?.[e].genres[0]}{" "}
                </span>
                <span className="text-black capitalize">
                  {novels?.[e].author.name}
                </span>
              </div>
              <p className="xl:line-clamp-7 mt-2 mb-2">
                {novels?.[e].description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
