import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import Modal from "../../../modal/Modal";
import { useState } from "react";
import { HeaderModalArgs } from "../Header";

export default function ChoosePlan({
  isOpen,
  closeModal,
}: HeaderModalArgs<"plan">) {
  const [plan, setPlan] = useState("");
  const handleSubscriptionPayment = () => {
    closeModal("subscribed", { plan });
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="modal__plan__container">
        <h1>Choose your plan</h1>
        <div className="modal__plan__checkbox__container__wrap">
          <div
            className="modal__plan__checkbox__container"
            onClick={() => setPlan("1 Week")}
          >
            <label className="modal__plan__checkbox__container__label">
              1 Week - $2.99
            </label>
            <span className="modal__plan__checkbox__container__check">
              {" "}
              {plan === "1 Week" ? <FaCheckCircle /> : <FaRegCircle />}
            </span>
          </div>
          <div
            className="modal__plan__checkbox__container"
            onClick={() => setPlan("1 Month")}
          >
            <label className="modal__plan__checkbox__container__label">
              1 Month - $2.99
            </label>
            <span className="modal__plan__checkbox__container__check">
              {" "}
              {plan === "1 Month" ? <FaCheckCircle /> : <FaRegCircle />}
            </span>
          </div>
          <div
            className="modal__plan__checkbox__container"
            onClick={() => setPlan("6 Months")}
          >
            <label className="modal__plan__checkbox__container__label">
              6 Months - $2.99
            </label>
            <span className="modal__plan__checkbox__container__check">
              {" "}
              {plan === "6 Months" ? <FaCheckCircle /> : <FaRegCircle />}
            </span>
          </div>
          <div
            className="modal__plan__checkbox__container"
            onClick={() => setPlan("12 Months")}
          >
            <label className="modal__plan__checkbox__container__label">
              12 Months - $2.99
            </label>
            <span className="modal__plan__checkbox__container__check">
              {" "}
              {plan === "12 Months" ? <FaCheckCircle /> : <FaRegCircle />}
            </span>
          </div>
        </div>
        <button onClick={handleSubscriptionPayment}>Continue to payment</button>
      </div>
    </Modal>
  );
}
