import { useQuery } from "@tanstack/react-query";
import { ListResponse, Novel } from "../../../api";

import { ColumnMap, useResponsive } from "../../../common/utils/useResponsive";
import Loading from "../../../components/splash/loading/Loading";
import NovelItem from "./NovelItem";

const _columnMap = {
  0: 6,
  640: 8,
  768: 8,
  1024: 12,
  1280: 14,
  1536: 16,
};

export default function NovelListing({
  query,
  title,
  columnMap = _columnMap,
}: {
  title: string;
  query: string;
  columnMap?: ColumnMap<number>;
}) {
  const count = useResponsive(columnMap);
  const { data: { results: novels } = {} } = useQuery<ListResponse<Novel>>({
    queryKey: ["/novels/", query, "&limit=" + count],
  });

  return (
    <>
      <h3 className="max-md:text-center sm:mt-8">{title}</h3>
      <div className="min-h-[480px] flex flex-row flex-wrap mt-8  gap-x-4 md:gap-x-8 2xl:gap-x-12">
        {novels?.length ? (
          novels
            .slice(0, count)
            .map((e) => (
              <NovelItem
                className="flex-grow w-30 max-[480px]:w-20 mb-8 max-sm:min-w-2/7"
                novel={e}
                key={e.id}
              />
            ))
        ) : (
          <Loading />
        )}
        <NovelItem className="flex-grow w-30 max-sm:min-w-2/7" placeholder />
        <NovelItem className="flex-grow w-30 max-sm:min-w-2/7" placeholder />
        <NovelItem className="flex-grow w-30 max-sm:min-w-2/7" placeholder />
        <NovelItem className="flex-grow w-30 max-sm:min-w-2/7" placeholder />
        <NovelItem className="flex-grow w-30 max-sm:min-w-2/7" placeholder />
        <NovelItem className="flex-grow w-30 max-sm:min-w-2/7" placeholder />
      </div>
    </>
  );
}
