import Modal from "../../../modal/Modal";
import {
  useAuth,
  usePasswordForget,
} from "../../../../redux/actions/authActions";
import logo from "../../../../assets/icons/logo.png";
import { HeaderModalArgs } from "../Header";
import { ClipLoader } from "react-spinners";
import { FormEvent } from "react";

export default function RequestResetPassword({
  isOpen,
  closeModal,
}: HeaderModalArgs<"reset-password">) {
  const auth = useAuth();
  const errorMessage = auth.error;
  const onPasswordForget = usePasswordForget();
  const handleForget = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onPasswordForget(
      (
        (ev.target as HTMLFormElement).elements.namedItem(
          "email"
        ) as HTMLInputElement
      ).value
    );
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="modal__login__container">
        <img src={logo} alt="" />
        <h1>Reset Password</h1>
        <h4 className="otp__form__text">
          Please enter the email address attached to your account
        </h4>

        <form className="modal__login__container__form" onSubmit={handleForget}>
          <input type="email" placeholder="Enter email" />

          {errorMessage && (
            <h3 className="login__error__message">{errorMessage}</h3>
          )}
          <button type="submit" disabled={auth.loading}>
            {auth.loading ? <ClipLoader color="#fff" size={20} /> : "Submit"}
          </button>
        </form>
      </div>
    </Modal>
  );
}
