import { useEffect, useState } from "react";
import { AiFillFileImage } from "react-icons/ai";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { FormInputProps, useField } from "./AppForm";

export default function FormImage({
  name,
  label,
  aspectRatio = 1,
}: FormInputProps & { label: string; aspectRatio?: number }) {
  const { value, update, error } = useField<File | string | null>(name);
  const [imageURL, setImageURL] = useState<string | undefined>();

  const fileName = value ? (value as File).name : "No file selected";

  useEffect(() => {
    let image: string | undefined;
    if (value instanceof File) {
      image = URL.createObjectURL(value);

      setImageURL(image);
    } else {
      setImageURL(value as string);
    }
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [value]);
  return (
    <>
      <label
        htmlFor={name}
        className={
          "image__upload max-h-20 mx-auto " +
          (error ? "border-error border" : "")
        }
        style={{ aspectRatio }}
      >
        {imageURL ? null : <span>{label}</span>}
        <input
          name={name}
          id={name}
          type="file"
          accept="image/*"
          className="input__field invisible absolute"
          onChange={({ target: { files } }) => {
            if (files && files.length) {
              update(files[0]);
            }
          }}
        />
        {value ? (
          <img
            src={imageURL}
            width={75}
            height={75}
            alt={fileName}
            className="input__preview"
          />
        ) : (
          <MdCloudUpload color="#669933" size={50} />
        )}
      </label>
      <div
        role="button"
        tabIndex={0}
        className="image__upload__label max-w-sm mx-auto cursor-pointer"
        onClick={() => {
          update(null);
        }}
      >
        <AiFillFileImage color="#1475" />
        <span className="ml-2">{fileName}</span>
        <span>
          <MdDelete className="inline ml-2" />
        </span>
      </div>
      {error && <div className="text-sm text-error text-center">{error}</div>}
    </>
  );
}
