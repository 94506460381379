import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { ListResponse, MyNovelChapter, Novel } from "../../../api";
import NovelChapterItem from "./NovelChapterItem";

export default function NovelChapterList({
  novelId,
  isDirty,
  activeChapter,
  onCreateNewChapter,
  onEditChapter,
}: {
  isDirty: boolean;
  novelId: Novel["id"];
  activeChapter: MyNovelChapter | null;
  onCreateNewChapter: () => void;
  onEditChapter: (e: MyNovelChapter | null) => void;
}) {
  const { data: { results: novelChapters } = {} } = useQuery<
    ListResponse<MyNovelChapter>
  >({
    queryKey: ["novels/mine/", novelId, "/chapters/"],
    enabled: !!novelId,
  });
  useEffect(() => {
    onEditChapter(
      novelChapters?.find((e) => e.chapter === activeChapter?.chapter) ??
        novelChapters?.[0] ??
        null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novelChapters]);
  const addNextChapter = useCallback(async () => {
    onCreateNewChapter();
  }, [onCreateNewChapter]);
  return (
    <section className="bg-white rounded-2xl w-80 xl:w-96 pr-2 max-lg:w-full">
      <div className="w-full book__chapters__nav">
        <button onClick={addNextChapter}>New Chapter</button>

        {novelChapters?.length ? (
          <div className="book__chapters__nav__chapters__wrap -mr-2">
            {/* {write ? <NovelChapterItem chapter={} /> : null} */}
            {novelChapters.map((chapter, index) => (
              <NovelChapterItem
                key={chapter.chapter}
                chapter={chapter}
                index={index}
                isActive={activeChapter?.chapter === chapter.chapter}
                onClick={() => {
                  onEditChapter(chapter);
                }}
              />
            ))}
          </div>
        ) : (
          <p className="text-gray-600 text-center py-4 w-full">
            No chapters created
          </p>
        )}
      </div>
    </section>
  );
}
