import { BsEye } from "react-icons/bs";
import { Novel } from "../../../api";
import formatNumber from "../../../common/utils/format_number";
import { Link, useNavigate } from "react-router-dom";

export default function NovelItem({
  novel,
  placeholder = false,
  className = "flex-grow w-30 mb-8",
}: {
  novel?: Novel;
  placeholder?: boolean;
  className?: string;
}) {
  return (
    <Link
      className={
        "flex flex-col hover:bg-tertiary/25 hover:scale-105 hover:p-1 rounded-md " +
        className
      }
      to={"/book/" + novel?.id}
    >
      {placeholder ? null : (
        <>
          <img src={novel?.cover_image} className="w-full novel-cover" />
          <div className="font-bold font-inter text-sm leading-tight mt-1 line-clamp-2 overflow-ellipsis mb-0.5">
            {novel?.title}
          </div>
          <div className="flex-grow" />
          <div className="flex capitalize justify-between text-sm">
            <span className=" ">{novel?.genres[0]}</span>
            <span className=" ">
              <BsEye className="inline-block align-middle mb-1" />{" "}
              {formatNumber(novel?.num_views || 0)}
            </span>
          </div>
        </>
      )}
    </Link>
  );
}
