import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const { isAuthorized } = useSelector((state) => state.auth);

  const isAuthenticated = isAuthorized;

  return isAuthenticated ? (
    <Outlet />
  ) : isAuthenticated === undefined ? null : (
    <Navigate to="/signin" />
  );
};

export default PrivateRoute;
