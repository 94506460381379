import { useNavigate } from "react-router-dom";
import Login from "../../../components/layout/header2/modals/Login";

function LoginScreen({ isSignup = false }) {
  const navigate = useNavigate();

  return (
    <div className="login">
      <Login
        isOpen={true}
        isSignUp={isSignup}
        closeModal={(e) => {
          switch (e) {
            case "reset-password":
              navigate("/reset-password");
              break;
            case "signup":
              navigate("/signup");
              break;
            case "forgot-password":
              navigate("/forgot-password");
              break;
            case "code":
              navigate("/otp");
              break;
            case "login":
              navigate("/signin");
              break;
            case "new-password":
              navigate("/successful-reset");
              break;
            default:
              navigate("/");
          }
        }}
      />
    </div>
  );
}

export default LoginScreen;
