import { ReactNode } from "react";
import { useField } from "./AppForm";

export default function FormComponent<T extends object>({
  name,
  children,
  ...props
}: T & {
  name: string;
  children: (
    args: Omit<T, "name" | "children"> & ReturnType<typeof useField>
  ) => ReactNode;
}) {
  const field = useField(name);
  return children({ ...props, ...field });
}
