export function shortenNumber(number: number) {
  if (number < 1_000) return number;
  if (number < 1_000_000) return Math.floor(number / 100) / 10 + "k";
  return Math.floor(number / 100_000) / 10 + "M";
}
export function formatPhoneNumber(number_string: number | string) {
  const [failed, countryCode, ...parts] = String(number_string ?? "").split(
    /^(\+\d\d\d)?(\d\d\d)?(?:(\d\d\d)?(\d\d\d)?|(\d?\d\d\d)?(\d\d\d)?)(\d\d\d\d?)?$/
  );
  if (failed) return failed;
  return (
    (countryCode ? countryCode + " " : "") + parts.filter(Boolean).join("-")
  );
}

export default function formatNumber(number_string: number | string) {
  const parts = String(number_string ?? "").split(
    /^(\d\d?)?(?:(\d\d\d)(?:(\d\d\d)(?:(\d\d\d)(?:(\d\d\d))?)?)?)?$/
  );
  return parts.filter(Boolean).join(",");
}
