import "./Home.css";

import Footer from "../../../components/layout/footer/Footer";
import ToasterContainer from "../../../components/toast/ToasterContainer";
import Meta from "../../../utils/Meta";

import "../../../utils/skeleton/home-skeleton.css";

import Features from "./Features";
import Header from "../../../components/layout/header2/Header";
import Landing from "./Landing";
import NovelListing from "./NovelListing";
import PopularNovels from "./PopularNovels";
import TopRanked from "./TopRanked";
import Trending from "./Trending";

function Home() {
  return (
    <div className="home">
      <ToasterContainer />
      <Meta title="Home - BONREADER" metaDescription="" metaKeywords={[""]} />
      <main className="home__main">
        <section className=" bg-bg sm:rounded-b-[128px] pb-16">
          <div className="py-8 container">
            <Header />
            <Landing />
          </div>
        </section>
        <Features />
        <div className="mt-24 container">
          <h6 className="max-md:text-center">Popular Novels</h6>
          <div className="flex flex-wrap gap-8 justify-center items-start">
            <PopularNovels />
            <div className="flex-grow w-2xl max-w-full">
              <NovelListing
                title=""
                query="?listing=popular"
                columnMap={{
                  "0": 6,
                  "640": 8,
                  "768": 8,
                  "1024": 12,
                  "1280": 8,
                  "1536": 10,
                }}
              />
            </div>
          </div>
          <div className="w-full lg:mt-8">
            <div className="flex flex-wrap gap-8 justify-center items-stretch w-full">
              <TopRanked />
              <Trending />
            </div>
          </div>
        </div>
        <div className="container">
          <NovelListing title="Top Romance" query="?genre=romance" />
        </div>
        <div className="bg-[fff6ef]">
          <div className="container">
            <NovelListing title="Top-Rated" query="?listing=topRanked" />
            <NovelListing
              title="Editor's Choice"
              query="?listing=editorialPicks"
            />
          </div>
        </div>
        <div className="container">
          <NovelListing title="New Arrivals" query="?listing=new" />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
