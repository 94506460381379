import Header from "../../../components/layout/header2/Header";
import image1 from "../../../assets/images/glasses-book-create.png";
import "./create.css";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "../../../components/layout/footer/Footer";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../components/splash/loading/Loading";
import { ListResponse, MyNovel } from "../../../api";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

function Create() {
  const navigate = useNavigate();
  const isAuthorized = useSelector((e: RootState) => e.auth.isAuthorized);
  const myStoriesQuery = useQuery<ListResponse<MyNovel>>({
    queryKey: ["novels/mine/"],
    enabled: isAuthorized,
  });
  return (
    <div className="create__bg">
      <div className="container pt-8">
        <Header />
      </div>
      <div className="create">
        {myStoriesQuery.isLoading ? (
          <Loading />
        ) : (myStoriesQuery.data?.results.length || 0) > 0 ? (
          <section className="create__section__two">
            <h1 className="create__section__two__heading">Your Stories</h1>
            <div className="create__section__two__container">
              <Navigate to="/account-stories" />
              <Loading />
            </div>
          </section>
        ) : (
          <section className="create__section__one">
            <img
              src={image1}
              alt="reading glasses on book"
              className="create__section__one__image"
            />
            <button
              className="create__section__one__button"
              onClick={() => navigate("/create-book")}
            >
              Create your first book now!
            </button>
          </section>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Create;
